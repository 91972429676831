/* eslint-disable import/no-extraneous-dependencies */
import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  fromPromise,
  HttpLink,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { Operation, NextLink } from '@apollo/client/link/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { ServerParseError } from '@apollo/client/link/http';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getAccessToken, getUserToken, signOut, Tokens } from '../services/auth';
import { cache, isLoggedInVar } from './cache';
import { typeDefs } from './typeDefs';
import { logError } from '../utils/logger';
import { refreshAccessToken } from '../utils/refreshAccessToken';
import { EMAIL_VERIFICATION_ERROR } from './selectors/selectErrorMessage';
import { uuid } from '../utils/uuid';

let isRefreshing = false;
let pendingRequestsQueue: Array<() => void> = [];

function addRequestsToQueue(func: () => void): void {
  pendingRequestsQueue.push(func);
}

function resolvePendingRequests(): void {
  pendingRequestsQueue.forEach((cb) => cb());
  pendingRequestsQueue = [];
}

function setIsRefreshing(refreshing: boolean): void {
  isRefreshing = refreshing;
}

// refresh access token with gql mutation solution courtesy of https://stackoverflow.com/a/62872754
// eslint-disable-next-line import/no-mutable-exports
let client: ApolloClient<NormalizedCacheObject>;

const refreshOnError = (operation: Operation, forward: NextLink) => {
  if (!isRefreshing) {
    setIsRefreshing(true);
    return fromPromise(
      refreshAccessToken(client).catch((e) => {
        logError({
          error: new Error('Failed to refresh the tokens'),
          originalError: e,
          filename: 'src/apollo/client.ts',
          tags: { userFlow: 'auth' },
        });
        setIsRefreshing(false);
        resolvePendingRequests();
        signOut();
        isLoggedInVar(false);
        // eslint-disable-next-line no-useless-return
        return;
      }),
    )
      .filter((value) => Boolean(value))
      .flatMap((data) => {
        setIsRefreshing(false);
        resolvePendingRequests();

        // We've already filtered out untruthy values here,
        // but we need to cast it to satisfy TypeScript.
        const { accessToken, userToken } = data as Tokens;

        const oldHeaders = operation.getContext().headers;
        // modify the operation context with a new token
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${accessToken}`,
            CdsUserJwt: userToken,
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_KEY,
          },
        });

        // retry the request, returning the new observable
        return forward(operation);
      });
  }
  // don't try to refresh the token as it's already being handled,
  // but instead add this request to the list so it can be
  // retried once the token has been refreshed.
  return fromPromise(
    new Promise<void>((resolve) => {
      addRequestsToQueue(() => resolve());
    }),
  ).flatMap(() => {
    return forward(operation);
  });
};

/**
 * Catches any errors in the graphql chain, checks if they are due to the user
 * being unauthenticated. If so, attempts to refresh the user's access token.
 * If that fails, it'll set the app to guest user mode. If we can successfully
 * refresh the access token, then both the access and refresh token which we store
 * are updated with the new ones, and the link completes (which results in a retry of the original request).
 *
 * ⚠️ Fairly complicated function for a fairly complicated set of behaviours. This function limits one refresh
 * request at a time, and creates a queue for any pending requests, which are completed once the refresh token request completes.
 *
 * See this https://www.apollographql.com/docs/react/data/error-handling/#on-graphql-errors
 * and this https://www.apollographql.com/docs/apollo-server/data/errors/#unauthenticated for more info.
 * See this SO post for more information on using a queue system for requests ->
 * https://stackoverflow.com/questions/50965347/how-to-execute-an-async-fetch-request-and-then-retry-last-failed-request/51321068#51321068
 */
const errorLink = onError(({ graphQLErrors, operation, forward, networkError }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      logError({ error: new Error(err.message), originalError: err, filename: 'graphql/client.ts errorLink' });
      switch (err.extensions?.code) {
        case 'UNAUTHENTICATED': {
          // We don't want to try refreshing tokens when user has not verified his email. Without this condition,
          // we end up in a loop when trying to log in.
          if (err.extensions?.response.body.error === EMAIL_VERIFICATION_ERROR) {
            return forward(operation);
          }
          return refreshOnError(operation, forward);
        }

        default: {
          // For any other gql error, let it pass through
          // eslint-disable-next-line consistent-return
          return;
        }
      }
    }
  }

  if (networkError) {
    const { statusCode } = networkError as ServerParseError;
    if (statusCode === 401 || statusCode === 403) {
      return refreshOnError(operation, forward);
    }

    logError({
      error: new Error(networkError.message),
      originalError: networkError,
      filename: 'graphql/client.ts errorLink',
      additionalInfo: {
        statusCode,
      },
    });
  }

  // For any other error, let it pass through
  // eslint-disable-next-line no-useless-return, consistent-return
  return;
});

const transactionId = uuid();
const transactionIdLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'x-transaction-id': transactionId,
  },
}));

const authLink = setContext((_, { headers }) => {
  const accessToken = getAccessToken();
  const userToken = getUserToken();
  if (!accessToken || !userToken) {
    return {
      headers: { ...headers, 'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_KEY },
    };
  }

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
      CdsUserJwt: userToken,
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_KEY,
    },
  };
});

export const defaultOptions: DefaultOptions = {
  mutate: {
    // It's imperative that we set this errorPolicy to 'all',
    // as this ensures we catch network and gql errors. Without this
    // errors such as 500 Internal Server Error do not get caught and assigned to
    // the error property returned by the mutation.
    // https://www.apollographql.com/docs/react/data/error-handling/#setting-an-error-policy
    errorPolicy: 'all',
  },
  query: {
    errorPolicy: 'all',
  },
};

// github.com/apollographql/apollo-client/issues/84#issuecomment-763833895
// Insecure GraphQL endpoint does not need JWT and for user to be logged in to call it
const insecureGraphQL = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL_INSECURE });
const secureGraphQL = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });
const wsSecureGraphQL = new GraphQLWsLink(
  createClient({
    url: `${process.env.REACT_APP_GRAPHQL_WS_URL!}?subscription-key=${process.env.REACT_APP_APIM_KEY}`,
    connectionParams: () => {
      return {
        Authorization: getAccessToken() ? `Bearer ${getAccessToken()}` : '',
        CdsUserJwt: getUserToken() ? getUserToken() : '',
      };
    },
  }),
);
const graphqlEndpoints = split(
  (operation) => operation.getContext().serviceName === 'insecure',
  insecureGraphQL,
  secureGraphQL,
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsSecureGraphQL,
  graphqlEndpoints,
);
const link = ApolloLink.from([errorLink, authLink, transactionIdLink, splitLink]);

// eslint-disable-next-line prefer-const
client = new ApolloClient({
  link,
  cache,
  typeDefs,
  defaultOptions,
});

export default client;
