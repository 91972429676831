import { useTheme } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddressPinIcon } from '../../assets/icons/address-pin_circled.svg';
import { ReactComponent as NotificationBellIcon } from '../../assets/icons/notification-bell_circled.svg';
import { ReactComponent as PersonIcon } from '../../assets/icons/person_circled.svg';
import { useContentBottomPadding } from '../../context/contentBottomPaddingContext';
import { useQueryParameters } from '../../hooks';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useContentAreaPadding } from '../AppLayout/useContentAreaPadding';
import TabPanel from '../TabPanel';
import AccountDetails from './AccountDetails';
import Label from './Label';
import MyAddress from './MyAddress';
import NotificationSettings from './NotificationSettings';
import { useMyAccountTabStyles } from './styles';
import { AccountSections } from './types';

const StyledTab = withStyles((theme) => ({
  root: {
    border: 'none',
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    textTransform: 'capitalize',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(6),

    // override the max-width that material-ui imposes (~264px)
    maxWidth: '100%',
  },
  selected: {
    // There is already a divider in place, which sits adjacent to these tabs. We can
    // therefore remove the top border from the first tab when it is selected.
    '&:first-child': {
      borderTop: 'none',
    },

    // Tabs have a border right when selected, let's remove that
    // so that there is no break in colour between the tab panel background
    // and the background of the selected tab.
    borderRight: 'none',
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
}))(Tab);

function selectSectionIndex(query: string | null): number | null {
  if (!query) {
    return null;
  }
  const sectionKey = AccountSections[Number(query)];
  if (sectionKey === undefined) {
    return null;
  }
  return Number(AccountSections[sectionKey as unknown as AccountSections]);
}

export default function MyAccountTabs(): JSX.Element {
  const query = useQueryParameters();
  const { desktopOnly } = useFlags();
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const appLayoutContentPadding = useContentAreaPadding();
  const history = useHistory();
  const { state: contentBottomPaddingState } = useContentBottomPadding();
  const classes = useMyAccountTabStyles({ appLayoutContentPadding });
  const { t } = useTranslation();
  const tabOpened = selectSectionIndex(query.get('tab'));
  const [selectedTab, setSelectedTab] = useState<AccountSections>(tabOpened || AccountSections.AccountDetails);
  function handleChange(_: React.ChangeEvent<unknown>, newValue: AccountSections): void {
    setSelectedTab(newValue);
    history.push(`/my-account?tab=${newValue}`);
  }

  useEffect(() => {
    if (tabOpened === null) {
      return;
    }
    setSelectedTab(tabOpened);
  }, [tabOpened]);

  const VerticalTabs = withStyles((_theme) => ({
    root: {
      backgroundColor: _theme.palette.grey[50],
      border: 'none',
      paddingLeft: 0,
      width: '25%',
      minWidth: 'fit-content',
    },
    flexContainer: {
      height: `calc(100vh + ${contentBottomPaddingState.padding}px)`,
      overflow: 'visible',
    },
    indicator: {
      width: 0,
    },
  }))(Tabs);

  return (
    <>
      {desktopOnly || !lessThanMd ? (
        <div className={classes.container_DEPRECATED}>
          <VerticalTabs onChange={handleChange} orientation="vertical" value={selectedTab}>
            <StyledTab
              label={<Label Icon={PersonIcon} text={t('account:navigation.accountDetails')} />}
              value={AccountSections.AccountDetails}
            />
            <StyledTab
              label={<Label Icon={AddressPinIcon} text={t('account:navigation.myAddress')} />}
              value={AccountSections.MyAddress}
            />
            {/* Disabled until ready as not part of MVP */}
            {/* <StyledTab
          label={<Label Icon={DocumentIcon} text={t('account:navigation.myDocuments')} />}
          value={AccountSections.MyDocuments}
          disabled
        /> */}
            <StyledTab
              label={<Label Icon={NotificationBellIcon} text={t('account:navigation.notifications')} />}
              value={AccountSections.Notifications}
            />
            {/* Disabled until ready as not part of MVP */}
            {/* <StyledTab
          label={<Label Icon={SettingsSlidersIcon} text={t('account:navigation.settings')} />}
          value={AccountSections.Settings}
          disabled
        /> */}

            {/* This final tab exists to ensure the right border is displayed the way to the bottom of the screen, since there
        is no elegant way in css to achieve the same result. */}
            <StyledTab disabled className={classes.phantomBorder} />
          </VerticalTabs>
          <TabPanel
            className={
              AccountSections.AccountDetails === selectedTab ? classes.tabPanelVisible : classes.tabPanelHidden
            }
            value={AccountSections.AccountDetails}
            index={selectedTab}
          >
            <AccountDetails className={classes.tabPanelContainer} />
          </TabPanel>
          <TabPanel
            className={AccountSections.MyAddress === selectedTab ? classes.tabPanelVisible : classes.tabPanelHidden}
            value={AccountSections.MyAddress}
            index={selectedTab}
          >
            <MyAddress className={classes.tabPanelContainer} />
          </TabPanel>
          {/* Disabled until ready as not part of MVP */}
          {/* <TabPanel
            className={AccountSections.MyDocuments === selectedTab ? classes.tabPanelVisible : classes.tabPanelHidden}
            value={AccountSections.MyDocuments}
            index={selectedTab}
          >
            <div />
          </TabPanel> */}

          <TabPanel
            className={AccountSections.Notifications === selectedTab ? classes.tabPanelVisible : classes.tabPanelHidden}
            value={AccountSections.Notifications}
            index={selectedTab}
          >
            <NotificationSettings className={classes.tabPanelContainer} />
          </TabPanel>
          {/* Disabled until ready as not part of MVP */}
          {/* <TabPanel
            className={AccountSections.Settings === selectedTab ? classes.tabPanelVisible : classes.tabPanelHidden}
            value={AccountSections.Settings}
            index={selectedTab}
          >
            <div />
          </TabPanel> */}
        </div>
      ) : (
        <div className={classes.responsiveContainer}>
          <AccountDetails className={classes.tabPanelContainer} />
          <MyAddress className={classes.tabPanelContainer} />
          <NotificationSettings className={classes.tabPanelContainer} />
        </div>
      )}
    </>
  );
}
