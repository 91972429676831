import { useMutation, useReactiveVar } from '@apollo/client';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import { useFormik } from 'formik';
import { GraphQLError } from 'graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { ReactComponent as CircledCrossIcon } from '../../assets/icons/circled-cross.svg';
import { appIsWorkingVar, isLoggedInVar, registerRedirectVar } from '../../graphql/cache';
import { selectErrorMessage, EMAIL_VERIFICATION_ERROR } from '../../graphql/selectors/selectErrorMessage';
import { logError } from '../../utils/logger';
import { useLoginFormStyles } from './styles';
import { graphql } from '../../__generated__';
import { updateAccessToken, updateRefreshToken, updateUserToken } from '../../services/auth';
import { isMobileDimensions } from '../../utils/isMobileDimensions';

export const LOGIN = graphql(`
  mutation Login($loginRequestInput: AuthLoginRequestInput!) {
    authLogin(loginRequestInput: $loginRequestInput) {
      accessToken
      refreshToken
      userToken
      mfaToken
    }
  }
`);

export const GET_MFA_AUTHENTICATORS = graphql(`
  mutation GetMFAAuthenticators($getMfaAuthenticatorsRequestInput: AuthGetMfaAuthenticatorsRequestInput!) {
    authMfaAuthenticators(getMfaAuthenticatorsRequestInput: $getMfaAuthenticatorsRequestInput) {
      mfaAuthenticators {
        id
        active
        authenticatorType
        name
        oobChannel
      }
    }
  }
`);

export const ISSUE_MFA_CHALLENGE = graphql(`
  mutation IssueMFAChallenge($mfaChallengeRequestInput: AuthMfaChallengeRequestInput!) {
    authMfaChallenge(mfaChallengeRequestInput: $mfaChallengeRequestInput) {
      challengeType
      oobCode
    }
  }
`);

interface LoginFormProps {
  onMFARequested: (args: { mfaToken: string; mfaOOBCode: string; emailAddress: string }) => void;
}

export default function LoginForm({ onMFARequested: mfaRequested }: LoginFormProps): JSX.Element {
  const { t } = useTranslation();
  const registerRedirect = useReactiveVar(registerRedirectVar);
  const classes = useLoginFormStyles();
  const isMobile = isMobileDimensions();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [login] = useMutation(LOGIN, {
    context: { serviceName: 'insecure' },
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });
  const [getMFAAuthenticators] = useMutation(GET_MFA_AUTHENTICATORS, {
    context: { serviceName: 'insecure' },
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });
  const [issueMFAChallenge] = useMutation(ISSUE_MFA_CHALLENGE, {
    context: { serviceName: 'insecure' },
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });

  const loginSchema = yup.object({
    emailAddress: yup.string().email(t('auth:emailAddress.required')).required(t('auth:emailAddress.required')),
    password: yup.string().required(t('auth:password.required')),
  });

  const formik = useFormik<yup.InferType<typeof loginSchema>>({
    // For password fill.
    validateOnMount: true,
    initialValues: {
      emailAddress: '',
      password: '',
    },
    onSubmit: async ({ emailAddress, password }) => {
      appIsWorkingVar(true);
      try {
        const { errors: loginErrors, data: loginData } = await login({
          variables: {
            loginRequestInput: {
              emailAddress,
              password,
              clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
              loginByApp: 'Zellar',
            },
          },
        });

        if (loginData) {
          const { accessToken, refreshToken, userToken } = loginData.authLogin;
          if (!accessToken || !refreshToken || !userToken) {
            throw new Error(t('auth:loginForm.failedToLogin'));
          }

          updateAccessToken(accessToken);
          updateRefreshToken(refreshToken);
          updateUserToken(userToken);
          isLoggedInVar(true);
          appIsWorkingVar(false);
          registerRedirectVar(null);
          return;
        }

        if (
          loginErrors &&
          loginErrors.length > 0 &&
          loginErrors[0].extensions &&
          loginErrors[0].extensions.response?.status !== 403
        ) {
          // If a user register and tries to login whithout confirming his email, show email confirmation error.
          if (loginErrors[0].extensions.response?.body.error === EMAIL_VERIFICATION_ERROR) {
            throw new Error(EMAIL_VERIFICATION_ERROR);
          }

          // 403 is an error we tolerate, it means we need to getMFAAuthenticators with mfaToken from the response
          throw new Error(selectErrorMessage(loginErrors));
        }

        // Network error - different typing (an object instead of array)
        if (loginErrors !== undefined && !loginErrors.length) {
          throw new Error(loginErrors.toString());
        }

        // TODO: Remove existing login logic after replacing verification code with link
        const mfaToken = loginErrors && (loginErrors as GraphQLError[])[0]?.extensions?.response.body.mfaToken;
        if (!mfaToken) {
          throw new Error(t('auth:loginForm.failedToLogin'));
        }

        const { data: mfaAuthenticatorsResponse, errors: mfaAuthenticatorsErrors } = await getMFAAuthenticators({
          variables: {
            getMfaAuthenticatorsRequestInput: {
              mfaToken,
            },
          },
        });

        if (mfaAuthenticatorsErrors) {
          throw new Error(selectErrorMessage(mfaAuthenticatorsErrors));
        }

        // only email is supported at time of writing, so
        // request the code be sent to the registered email address.
        const email = mfaAuthenticatorsResponse!.authMfaAuthenticators.mfaAuthenticators.find(
          (authenticator) => authenticator.oobChannel === 'email',
        );
        if (!email) {
          throw new Error(t('auth:loginForm.failedToLogin'));
        }

        const { data: mfaData, errors: mfaErrors } = await issueMFAChallenge({
          variables: {
            mfaChallengeRequestInput: {
              clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
              authenticatorId: email.id,
              mfaToken,
            },
          },
        });
        if (mfaErrors) {
          throw new Error(selectErrorMessage(mfaErrors));
        }

        if (!mfaData) {
          throw new Error(t('auth:loginForm.failedToLogin'));
        }

        appIsWorkingVar(false);
        mfaRequested({ mfaOOBCode: mfaData.authMfaChallenge.oobCode, mfaToken, emailAddress });
      } catch (e) {
        appIsWorkingVar(false);
        logError({
          error: new Error('Failed to login'),
          originalError: e as Error,
          filename: 'LoginForm',
        });
        setError(e as Error);
      }
    },
    validationSchema: loginSchema,
  });

  return (
    <form className={classes.container} onSubmit={formik.handleSubmit}>
      <Typography variant="h1">{t('auth:loginForm.title')}</Typography>
      {error && (
        <Alert icon={<CircledCrossIcon stroke="white" />} severity="error">
          {error.message}
        </Alert>
      )}
      {registerRedirect && registerRedirect.redirectSuccess === false && (
        <Alert icon={false} severity="error">
          {registerRedirect.redirectMessage}
        </Alert>
      )}
      {registerRedirect && registerRedirect.redirectSuccess === true && (
        <Alert icon={false} severity="success">
          {t('auth:loginForm.redirectSuccess')}{' '}
          <a style={{ color: '#1b5e20' }} href="cultx://login">
            {isMobile && t('auth:loginForm.redirectApp')}
          </a>
        </Alert>
      )}
      <div className={classes.field}>
        <InputLabel className={classes.label} htmlFor="emailAddress">
          <Typography variant="subtitle1">{t('auth:emailAddress.label')}</Typography>
        </InputLabel>
        <TextField
          data-public
          id="emailAddress"
          name="emailAddress"
          value={formik.values.emailAddress}
          placeholder={t('auth:emailAddress.label')}
          onChange={formik.handleChange}
          error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
          helperText={formik.touched.emailAddress && formik.errors.emailAddress}
          variant="outlined"
          type="email"
        />
      </div>
      <div className={classes.field}>
        <InputLabel className={classes.label} htmlFor="password">
          <Typography variant="subtitle1">{t('auth:password.label')}</Typography>
        </InputLabel>
        <TextField
          id="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Link
        color="textSecondary"
        underline="always"
        variant="body2"
        component={RouterLink}
        className={classes.forgotPasswordLink}
        to="/reset-password"
      >
        {t('auth:loginForm.forgotPasswordLink')}
      </Link>
      <Button type="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
        {t('common:login')}
      </Button>
    </form>
  );
}
