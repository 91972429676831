import React from 'react';
import { useQuery } from '@apollo/client';
import { Link as RRLink } from 'react-router-dom';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactComponent as WineIcon } from '../../assets/icons/wine.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import Typography from '../Typography';
import BuyTradeActionButton, { Size } from '../BuyTradeActionButton';
import IconButton from '../IconButton';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-outline.svg';
import { BASIC_ASSET_BY_ASSET_ID } from '../../graphql/queries/basicWine';
import { selectActiveUnitSize } from '../Select/selectors';
import { selectNormalisedProduct } from '../ProductSummary/selectors';
import { getKycStatus } from '../../services/auth';
import { KycStatus, Asset } from '../../__generated__/graphql';
import { logError } from '../../utils/logger';

const useStyles = makeStyles<Theme, { borderColour: string }>((theme) => ({
  container: {
    borderBottom: (props) => `8px solid ${props.borderColour}`,
  },
  asset: {
    display: 'flex',
    height: 'inherit',
    gap: theme.spacing(3),
    padding: '20px 12px 32px 12px',
    justifyContent: 'space-around',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  middle: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  empty: {
    display: 'flex',
    height: 'inherit',
  },
  addIconButton: {
    margin: 'auto',
    '&:hover': {
      opacity: 0.5,
      backgroundColor: theme.palette.secondary.contrastText,
    },
    backgroundColor: theme.palette.secondary.contrastText,
  },
  icon: {
    padding: '3px 0',
  },
  removeIconButton: {
    stroke: theme.palette.textPrimary,
    marginBottom: 'auto',
    padding: 0,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}));

interface AssetTileCardProps {
  assetId: number;
  colour: string;
  className?: string;
  cardsPerPage?: 2 | 4;
  onRemoveClicked: () => void;
  onBidClicked?: () => void;
  onOfferClicked?: () => void;
  onBuyNowClicked?: () => void;
}

export default function AssetTileCard({
  assetId,
  colour,
  cardsPerPage,
  onRemoveClicked,
  onBidClicked,
  onOfferClicked,
  onBuyNowClicked,
  className = '',
}: AssetTileCardProps): JSX.Element {
  const classes = useStyles({ borderColour: colour });
  const { kyc: kycFlagEnabled } = useFlags();
  let disabledBuyTrade = getKycStatus() !== KycStatus.successful;
  if (!kycFlagEnabled) {
    disabledBuyTrade = false;
  }
  const {
    data,
    loading: gettingAsset,
    error: failedToGetAsset,
  } = useQuery(BASIC_ASSET_BY_ASSET_ID, {
    variables: {
      assetId: Number(assetId),
    },
    // https://github.com/apollographql/apollo-client/issues/6190
    // skip: isUndefined(assetId),
  });

  const { displayName } = React.useMemo(() => selectNormalisedProduct(data?.productAsset as Asset), [data]);
  const activeUnitSize = React.useMemo(() => selectActiveUnitSize(data?.productAsset), [data]);

  React.useEffect(() => {
    if (failedToGetAsset) {
      logError({
        error: new Error(failedToGetAsset.message),
        originalError: failedToGetAsset,
        filename: 'AssetTileCard',
      });
    }
  }, [failedToGetAsset]);

  return (
    <div className={`${classes.container} ${className}`}>
      {/* TODO: We currently have no contingency in place if the data is null for whatever reason. */}
      {gettingAsset ? (
        <Skeleton style={{ width: '100%', height: 'inherit' }} variant="rectangular" />
      ) : (
        <div className={classes.asset}>
          <div className={classes.icon}>
            <WineIcon />
          </div>
          <div className={classes.middle}>
            <div className={classes.text}>
              <Link component={RRLink} color="textPrimary" to={`/product/${data?.productAsset?.id}`}>
                {/**
                 * I have put this display name logic in place until we address the fixed height nature of the cards. This and increasing
                 * the height to 200px should hopefully avoid any places where the name and other gubbins do not fit on the cards.
                 * Related to https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/7171 &
                 * https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/6939
                 */}
                <Typography customVariant={displayName.length > 30 && cardsPerPage === 4 ? 'subtitle2' : 'subtitle1'}>
                  {displayName}
                </Typography>
              </Link>
              <Typography customVariant="body2" zellarColour="textLabel" className={classes.uppercase}>
                {activeUnitSize?.label}
              </Typography>
            </div>
            {onBidClicked && onOfferClicked && (
              <BuyTradeActionButton
                onBidClicked={onBidClicked}
                onOfferClicked={onOfferClicked}
                onBuyClicked={onBuyNowClicked}
                size={Size.Small}
                assetId={assetId}
                condensed
                disabled={disabledBuyTrade}
              />
            )}
          </div>
          <div>
            <IconButton className={classes.removeIconButton} onClick={onRemoveClicked} size="large">
              <CrossIcon />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}

interface EmptyAssetTileCardProps {
  borderColour: string;
  onAddClicked: () => void;
  className?: string;
}

/**
 * Because we cannot have assetId prop on AssetTileCard as optional
 * and determine whether to render the empty state or not from within
 * that component (due to the query firing despite passing the conditional
 * skip config, see https://github.com/apollographql/apollo-client/issues/6190),
 * we expose the empty card component here so that parent can conditionally
 * render the AssetTileCard or this component if assetId is undefined.
 */
export function EmptyAssetTileCard({
  borderColour,
  onAddClicked,
  className = '',
}: EmptyAssetTileCardProps): JSX.Element {
  const classes = useStyles({ borderColour });
  return (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.empty}>
        <IconButton variant="filled" className={classes.addIconButton} onClick={onAddClicked} size="large">
          <PlusIcon />
        </IconButton>
      </div>
    </div>
  );
}
