import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useOrderBookEnabled } from '../../hooks/useOrderBookEnabled';
import { logError } from '../../utils/logger';
import OrderBook from '../OrderBook';
import ProductLineChart from '../ProductLineChart';
import ProductVintageBarChart from '../ProductVintageBarChart';
import TabPanel from '../TabPanel';
import { graphql } from '../../__generated__';

const useStyles = makeStyles(() => ({
  tab: {
    minWidth: '33%',
  },
}));

const GET_VINTAGE_ID = graphql(`
  query WineIdForAsset($assetId: Int!) {
    productAsset(assetId: $assetId) {
      vintage {
        id
      }
    }
  }
`);

interface Props {
  assetId: number;
}

export default function ProductTabs({ assetId }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  // Commenting this out for now for testing purposes.
  // const { orderBookEnabled } = useOrderBookEnabled();
  const orderBookEnabled = true;
  const { data, error } = useQuery(GET_VINTAGE_ID, {
    variables: {
      assetId,
    },
  });
  if (error) {
    logError({
      originalError: error,
      error: new Error('Failed to get productAsset vintage Id'),
      filename: 'ProductChartTabs',
    });
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // todo fetch unitSize by unitSizeId via gql, it will be cached
  const unitSize = '6x75cl';
  return (
    <div>
      <Tabs value={value} indicatorColor="primary" onChange={handleChange} variant="fullWidth">
        <Tab className={classes.tab} label={t('product:marketPerformance')} />
        {orderBookEnabled && <Tab className={classes.tab} label={t('product:orderBook')} />}
        <Tab className={classes.tab} label={t('product:vintagePer')} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ProductLineChart assetId={assetId} />
      </TabPanel>
      {orderBookEnabled && (
        <TabPanel value={value} index={1}>
          <OrderBook selectedAssetId={assetId} wineVintageId={data?.productAsset?.vintage.id} />
        </TabPanel>
      )}
      <TabPanel value={value} index={orderBookEnabled ? 2 : 1}>
        <ProductVintageBarChart title={t('product:marketPricePer', { unitSize })} assetId={assetId} />
      </TabPanel>
    </div>
  );
}
