/* eslint-disable no-nested-ternary */
import React from 'react';
import { SortDirection } from '@cultwines/zellar-client-sdk';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import EmptyPlaceholder from '../EmptyPlaceholder';
import RowSkeleton from '../TableElements/RowSkeleton';
import TableCellHead from '../TableElements/TableCellHead';
import { WatchlistAsset, WatchlistFacets } from '../../views/WatchList/selectors';
import WineNameCell from '../Table/Cells/WineName';
import BasicCell from '../Table/Cells/Basic';
import ScoreCell from '../Table/Cells/Score';
import UnitSizeCell from '../Table/Cells/UnitSize';
import { formatter } from '../../utils/currencyFormatter';
import SparklineCell from '../Table/Cells/Sparkline';
import TradingTile from '../TradingTile';
import ActionsCell from '../TableElements/ActionsCell';
import BuyTradeActionButton, { Size as BuyTradeButtonSize } from '../BuyTradeActionButton';
import IconButton from '../IconButton';
import { ReactComponent as Trash } from '../../assets/images/trash.svg';
import ErrorPlaceholder from '../ErrorPlaceholder';
import { useSelectedRowsContext } from '../../context/selectedRowsContext';
import CheckboxCell from '../TableElements/Controls/CheckboxCell';
import isUndefined from '../../utils/isUndefined';

interface WatchlistTableProps {
  assets: WatchlistAsset[];
  loading?: boolean;
  error: string | null;
  perPage?: number;
  sortDirection: SortDirection;
  sortColumn: keyof WatchlistFacets;
  onSortChanged: (key: keyof WatchlistFacets) => void;
  onBuyNowClicked: (assetId: number) => void;
  onBidClicked: (assetId: number) => void;
  onOfferClicked: (assetId: number) => void;
  onDeleteClicked: (assetId: number) => void;
  onEmptyClicked: () => void;
}

export default function WatchlistTable({
  assets,
  loading,
  error,
  perPage,
  sortColumn,
  sortDirection,
  onSortChanged,
  onBidClicked,
  onBuyNowClicked,
  onDeleteClicked,
  onOfferClicked,
  onEmptyClicked,
}: WatchlistTableProps) {
  const { t } = useTranslation();
  const { state, dispatch } = useSelectedRowsContext();
  const columnCount = state.selectModeActive ? 11 : 10;
  const { clickableTradeTiles13816 } = useFlags();

  function handleCheckboxClicked(assetId: number): void {
    dispatch({
      type: state.selectedRows.map((sr) => Number(sr)).includes(assetId) ? 'rowUnchecked' : 'rowChecked',
      payload: assetId.toString(),
    });
  }

  return (
    <TableContainer>
      <Table aria-label="cultx portfolio table">
        <TableHead>
          <TableRow>
            {state.selectModeActive && <TableCellHead hideSortButton />}
            <TableCellHead
              sortActive={sortColumn === 'name'}
              sortDirection={sortColumn === 'name' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('name')}
              title={t('portfolio:table.headers.wineName')}
            />
            <TableCellHead
              sortActive={sortColumn === 'vintage'}
              sortDirection={sortColumn === 'vintage' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('vintage')}
              title={t('common:vintage')}
            />
            <TableCellHead
              sortActive={sortColumn === 'unitCount'}
              sortDirection={sortColumn === 'unitCount' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('unitCount')}
              title={t('common:unitSize')}
            />
            <TableCellHead
              sortActive={sortColumn === 'score'}
              sortDirection={sortColumn === 'score' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('score')}
              title={t('portfolio:table.headers.score')}
            />
            <TableCellHead
              sortActive={sortColumn === 'marketValue'}
              sortDirection={sortColumn === 'marketValue' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('marketValue')}
              title={t('product:marketValue')}
              tooltip={t('market:marketValueCalculation')}
            />
            <TableCellHead
              sortActive={sortColumn === 'performancePercentageDelta'}
              sortDirection={sortColumn === 'performancePercentageDelta' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('performancePercentageDelta')}
              title={t('market:performanceLTM')}
            />
            <TableCellHead
              sortActive={sortColumn === 'highestBid'}
              sortDirection={sortColumn === 'highestBid' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('highestBid')}
              title={t('product:trading.highestBid')}
            />
            <TableCellHead
              sortActive={sortColumn === 'lowestOffer'}
              sortDirection={sortColumn === 'lowestOffer' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('lowestOffer')}
              title={t('product:trading.lowestOffer')}
            />
            <TableCellHead title={t('common:actions')} hideSortButton />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <RowSkeleton columnCount={columnCount} rowCount={perPage} />
          ) : error ? (
            <TableRow>
              <TableCell colSpan={columnCount}>
                <ErrorPlaceholder error={error} />
              </TableCell>
            </TableRow>
          ) : assets.length ? (
            assets.map((asset) => (
              <TableRow key={asset.assetId}>
                {state.selectModeActive && (
                  <CheckboxCell
                    checked={state.selectedRows.map((sr) => Number(sr)).includes(asset.assetId)}
                    disabled={state.limitReached && !state.selectedRows.map((sr) => Number(sr)).includes(asset.assetId)}
                    onCheckedChanged={() => handleCheckboxClicked(asset.assetId)}
                  />
                )}
                <WineNameCell
                  wineName={asset.name}
                  appellation={asset.appellation}
                  link={`/product/${asset.assetId}`}
                  region={asset.region}
                />
                <BasicCell mainContent={asset.vintage} />
                <UnitSizeCell unitCount={asset.unitCount} unitSize={asset.unitSize} />
                <ScoreCell score={asset.score} />
                <BasicCell mainContent={formatter.format(asset.marketValue)} />
                <SparklineCell
                  data={asset.marketData}
                  deltaNum={asset.performanceValueDelta}
                  deltaPct={asset.performancePercentageDelta}
                />
                <TableCell>
                  {clickableTradeTiles13816 ? (
                    <TradingTile mode="bid" value={asset.highestBid} onClick={() => onBidClicked(asset.assetId)} />
                  ) : (
                    <TradingTile
                      mode="bid"
                      value={asset.highestBid}
                      unitCount={asset.unitCount}
                      unitSize={asset.unitSize}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {clickableTradeTiles13816 ? (
                    <TradingTile
                      mode="offer"
                      value={asset.lowestOffer}
                      onClick={!isUndefined(asset.lowestOffer) ? () => onBuyNowClicked(asset.assetId) : undefined}
                    />
                  ) : (
                    <TradingTile
                      mode="offer"
                      value={asset.lowestOffer}
                      unitCount={asset.unitCount}
                      unitSize={asset.unitSize}
                    />
                  )}
                </TableCell>
                <ActionsCell border="left">
                  <BuyTradeActionButton
                    assetId={asset.assetId}
                    onBidClicked={() => onBidClicked(asset.assetId)}
                    onOfferClicked={() => onOfferClicked(asset.assetId)}
                    onBuyClicked={() => onBuyNowClicked(asset.assetId)}
                    size={BuyTradeButtonSize.Small}
                    condensed
                  />
                  <IconButton key="delete-button" onClick={() => onDeleteClicked(asset.assetId)} size="large">
                    <Trash style={{ width: '1em', height: '1em' }} />
                  </IconButton>
                </ActionsCell>
              </TableRow>
            ))
          ) : (
            <EmptyPlaceholder
              label={t('product:watchList.emptyTable')}
              ActionButton={
                <Button variant="contained" color="primary" size="large" onClick={onEmptyClicked}>
                  {t('common:discover')}
                </Button>
              }
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
