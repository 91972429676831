import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BulletPoints from './BulletPoints';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}));

export default function WineDetailsSection(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h3">{t('product:wineDetails')}</Typography>
      <BulletPoints />
    </div>
  );
}
