import React from 'react';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import RecentSearchComponent, { RecentSearch } from './RecentSearch';
import Typography from '../Typography';
import Divider from '../Divider';

export type { RecentSearch };

const useStyles = makeStyles<Theme, { parentWidth: number }>((theme) => ({
  root: {
    zIndex: theme.zIndex.modal,
    width: (props) => props.parentWidth,
  },
  header: {
    padding: '10px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  title: {
    marginLeft: '4px',
  },
  innerWrapper: {
    padding: '8px 0',
  },
  inner: {
    maxHeight: '200px',
    overflowY: 'auto',

    '@media (min-height: 700px)': {
      maxHeight: '300px',
    },

    '@media (min-height: 850px)': {
      maxHeight: '400px',
    },
  },
  emptyContainer: {
    padding: '4px 16px 10px 20px',
    fontStyle: 'italic',
  },
}));

interface RecentSearchesProps {
  searches: RecentSearch[];
  searchSelected: (search: RecentSearch) => void;
  open: boolean;
  anchorEl: null | HTMLInputElement;
}

export default function RecentSearches({ anchorEl, open, searchSelected, searches }: RecentSearchesProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles({ parentWidth: anchorEl?.clientWidth || 0 });
  const isOpen = Boolean(anchorEl && open);
  return (
    <Popper
      className={classes.root}
      anchorEl={anchorEl}
      open={isOpen}
      modifiers={[
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
      ]}
      disablePortal
      keepMounted
    >
      <Paper>
        <div className={classes.innerWrapper}>
          <div className={classes.inner}>
            <div className={classes.header}>
              <Typography className={classes.title} customVariant="h6">
                {t('discover:recentSearches.title')}
              </Typography>
              <Divider />
            </div>
            {searches.map((search) => (
              <RecentSearchComponent key={search.id} item={search} onClick={() => searchSelected(search)} />
            ))}
            {searches.length === 0 && (
              <div className={classes.emptyContainer}>
                <Typography customVariant="body1" zellarColour="textInformation">
                  {t('discover:recentSearches.empty')}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </Popper>
  );
}
