import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import SuggestionGroupHeader from './SuggestionGroupHeader';

const useStyles = makeStyles(() => ({
  container: {},
  children: {},
}));

interface SuggestionGroupProps {
  title: string;
  children: React.ReactChild;
}

export default function SuggestionGroup({ title, children }: SuggestionGroupProps): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <SuggestionGroupHeader title={title} />
      <div className={classes.children} data-cy="suggestions">
        {children}
      </div>
    </div>
  );
}
