import { useReactiveVar } from '@apollo/client';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ProductChartTabs from '../../components/ProductChartTabs';
import ProductSummary from '../../components/ProductSummary';
import Details from '../../components/ProductTabSections';
import ProductTradingSection from '../../components/ProductTradingSection';
import Typography from '../../components/Typography';
import { isLoggedInVar } from '../../graphql/cache';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useStyles } from './styles';
import CardPaymentErrorModal from '../../components/CardPaymentCallbackModal/errorContent';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';
import CardPaymentSuccessModal from '../../components/CardPaymentCallbackModal/successContent';

export default function Product(): JSX.Element {
  const theme = useTheme();
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const history = useHistory();
  const location = useLocation();
  const { showCarPaymentErrorModal, showCarPaymentSuccessModal } = useCardPaymentCallback();

  function handleSignInClick(): void {
    history.push('/login');
  }
  function closeCardPaymentErrorModal() {
    history.push({ pathname: location.pathname, search: location.search, hash: '' });
  }
  function closeCardPaymentSuccessModal() {
    history.push({ pathname: location.pathname, search: location.search, hash: '' });
  }

  // the asset id
  const { id } = useParams<{ id: string | undefined }>();

  return (
    <Grid className={classes.container} spacing={greaterThanSm ? 10 : 4} container>
      <Grid className={classes.leftColumn} item xs={12} sm={12} md={12} lg={8}>
        <ProductSummary assetId={Number(id)} />
        <ProductChartTabs assetId={Number(id)} />
      </Grid>
      <Grid className={classes.rightColumn} item xs={12} sm={12} md={12} lg={4}>
        <Grid container spacing={greaterThanSm ? 10 : 4}>
          <Grid className={classes.tradingSection} item xs={12} sm={6} md={6} lg={12} xl={12}>
            {isLoggedIn ? (
              <ProductTradingSection assetId={Number(id)} />
            ) : (
              <div className={classes.guestProductTradingSection}>
                <Typography customVariant="subtitle1">{t('product:trading.guestUser')}</Typography>
                <Button onClick={handleSignInClick} variant="contained" color="primary">
                  {t('common:login')}
                </Button>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={12} xl={12}>
            <Details assetId={Number(id)} />
          </Grid>
        </Grid>
      </Grid>
      {showCarPaymentErrorModal && (
        <CardPaymentErrorModal open={showCarPaymentErrorModal} onClose={closeCardPaymentErrorModal} />
      )}
      {showCarPaymentSuccessModal && (
        <CardPaymentSuccessModal open={showCarPaymentSuccessModal} onClose={closeCardPaymentSuccessModal} />
      )}
    </Grid>
  );
}
