import React from 'react';
import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ActionsCell from './Cells/Actions';
import { Actions, ExpandedContentProps, Headers, Row } from './types';
import { isBasic, isCustom, isDrinkingWindow, isScore, isSparkline, isUnitSize, isWineName } from './utils';
import ScoreCellComponent from './Cells/Score';
import WineNameCellComponent from './Cells/WineName';
import BasicCellComponent from './Cells/Basic';
import SparklineCellComponent from './Cells/Sparkline';
import UnitSizeCellComponent from './Cells/UnitSize';
import CustomCellComponent from './Cells/Custom';
import DrinkingWindow from './Cells/DrinkingWindow';
import Checkbox from '../Checkbox';
import { useSelectedRowsContext } from '../../context/selectedRowsContext';
import { OWNED_ASSET_INSTANCES_QUERY } from '../../graphql/queries/ownedAssetInstance';
import useCanCreateOffer from '../../hooks/useCanCreateOffer';
import { useLastIndexAvailableForOffer } from '../../hooks/useLastIndexAvailableForOffer';
import { logError } from '../../utils/logger';

const useStyles = makeStyles((theme) => ({
  actions: {
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
  },
  expanderCell: {
    padding: 0,
  },
  subTableCell: {
    padding: 0,
    border: 'none',
  },
  checkbox: {
    '&.Mui-disabled': {
      color: theme.palette.grey[100],
    },
  },
  transferContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
  },
}));

interface RowProps<T extends Row, HeaderKey> {
  datum: T;
  headers: Headers<Omit<T, 'rowId' | 'actions' | 'asset'>, HeaderKey>;
  columnCount: number;
  actions?: Actions;
  SubContent?: (args: ExpandedContentProps) => JSX.Element;
  disableSelect?: boolean;
}

// eslint-disable-next-line consistent-return, @typescript-eslint/explicit-module-boundary-types
export function renderCell<T>(arg: T) {
  if (isScore(arg)) {
    return <ScoreCellComponent {...arg} />;
  }
  if (isWineName(arg)) {
    return <WineNameCellComponent {...arg} />;
  }
  if (isBasic(arg)) {
    return <BasicCellComponent {...arg} />;
  }
  if (isSparkline(arg)) {
    return <SparklineCellComponent {...arg} />;
  }
  if (isUnitSize(arg)) {
    return <UnitSizeCellComponent {...arg} />;
  }
  if (isCustom(arg)) {
    return <CustomCellComponent {...arg} />;
  }
  if (isDrinkingWindow(arg)) {
    return <DrinkingWindow {...arg} />;
  }
}

/**
 * ⚠️ This component relies on the `SelectedRowsProvider` being placed higher up in the component tree.
 *
 * @deprecated Use the material-ui components instead, in conjunction with src/components/TableElements
 */
export default function RowComponent<T extends Row, HeaderKey>({
  datum,
  headers,
  actions,
  columnCount,
  SubContent,
  disableSelect = false,
}: RowProps<T, HeaderKey>): JSX.Element {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { state, dispatch } = useSelectedRowsContext();
  const {
    data: assetInstancesQueryResponse,
    loading: loadingAssetInstances,
    error: assetInstancesQueryError,
  } = useQuery(OWNED_ASSET_INSTANCES_QUERY, {
    variables: { assetId: Number(datum.rowId) },
    skip: !state.selectTransferModeActive,
  });

  const assetInstances = assetInstancesQueryResponse?.productAssetInstances || [];
  const { canCreateOffer, error: canCreateOfferError } = useCanCreateOffer({ assetId: Number(datum.rowId) });
  const { lastIndexAvailableForOffer } = useLastIndexAvailableForOffer({
    assetId: Number(datum.rowId),
  });

  if (assetInstancesQueryError) {
    logError({
      originalError: assetInstancesQueryError,
      error: new Error('Failed to get asset instances'),
      filename: 'Table/Row',
      additionalInfo: {
        rowId: datum.rowId,
      },
    });
  }

  if (canCreateOfferError) {
    logError({
      originalError: canCreateOfferError,
      error: new Error('Failed to find out if user can create offer'),
      filename: 'Table/Row',
      additionalInfo: {
        rowId: datum.rowId,
      },
    });
  }

  const transferAssetsCheckboxDisabled = assetInstances.filter((instance) => !instance.transferRequested).length === 0;

  function handleCheckboxChanged(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.checked) {
      dispatch({ type: 'rowChecked', payload: datum.rowId });
    } else {
      dispatch({ type: 'rowUnchecked', payload: datum.rowId });
    }
  }

  function handleTransferCheckboxChanged(e: React.ChangeEvent<HTMLInputElement>): void {
    if (!datum.asset) {
      return;
    }
    if (e.target.checked) {
      const parent = { type: 'ParentAsset', id: Number(datum.rowId), assetId: Number(datum.rowId) };
      const selectedInstanceRows = assetInstances
        .filter((instance, index) => !instance.transferRequested && index <= lastIndexAvailableForOffer)
        .map((instance) => {
          return {
            type: 'AssetInstance',
            id: instance.id,
            assetId: instance.assetId,
            currentLocation: instance.location,
            purchasedPrice: instance.purchasePrice,
            wineName: datum.asset!.name,
            unitSize: `${datum.asset!.unitCount} x ${datum.asset!.unitSize}`,
            marketValue: datum.asset!.marketValue,
          };
        });
      dispatch({ type: 'allTransferRowsChecked', payload: [...selectedInstanceRows, parent] });
    } else {
      const selectedAssetInstances = state.selectedTransferedRows.filter(
        (row) => (row.assetId as number) === Number(datum.rowId),
      );

      const parentAsset = { type: 'ParentAsset', id: Number(datum.rowId), assetId: Number(datum.rowId) };
      const assetInstanceIds = selectedAssetInstances?.map((instance) => ({ id: instance.id as string })) || [];
      dispatch({
        type: 'allTransferRowsUnchecked',
        payload: [...assetInstanceIds, { id: parentAsset.id.toString() }],
      });
    }
  }
  return (
    <>
      <TableRow key={datum.rowId}>
        <>
          {SubContent && state.selectTransferModeActive && !state.selectModeActive && (
            <TableCell className={classes.actions}>
              <div className={classes.transferContainer}>
                <Checkbox
                  inputProps={{ 'aria-label': 'row-checkbox' }}
                  className={classes.checkbox}
                  checked={
                    (state.selectedTransferedRows.map((x) => x.id).includes(Number(datum.rowId)) ||
                      transferAssetsCheckboxDisabled) &&
                    !loadingAssetInstances
                  }
                  color="primary"
                  onChange={handleTransferCheckboxChanged}
                  disabled={!canCreateOffer || transferAssetsCheckboxDisabled || loadingAssetInstances}
                />

                <IconButton aria-label="expand row" onClick={() => setExpanded(!expanded)} size="large">
                  {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              </div>
            </TableCell>
          )}
          {state.selectModeActive && !disableSelect && (
            <TableCell>
              <Checkbox
                className={classes.checkbox}
                checked={state.selectedRows.includes(datum.rowId)}
                color="primary"
                onChange={handleCheckboxChanged}
                disabled={!state.selectedRows.includes(datum.rowId) && state.limitReached}
              />
            </TableCell>
          )}
          {SubContent && !state.selectModeActive && !state.selectTransferModeActive && (
            <TableCell className={classes.expanderCell}>
              <IconButton aria-label="expand row" onClick={() => setExpanded(!expanded)} size="large">
                {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </TableCell>
          )}
          {Object.keys(headers).map((header) => renderCell(datum[header as keyof typeof datum]))}
          {actions && Object.keys(actions).length > 0 && (
            <ActionsCell
              className={classes.actions}
              actions={actions}
              datum={datum}
              disabled={state.selectModeActive}
            />
          )}
        </>
      </TableRow>
      {SubContent && state.expandedTransferContent && (
        <TableRow>
          <TableCell className={classes.subTableCell} colSpan={columnCount}>
            <Collapse in={expanded} unmountOnExit>
              <SubContent rowId={datum.rowId} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      {SubContent && expanded && !state.expandedTransferContent && !state.selectModeActive && (
        <TableRow>
          <TableCell className={classes.subTableCell} colSpan={columnCount}>
            <Collapse in={expanded} unmountOnExit>
              <SubContent rowId={datum.rowId} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
