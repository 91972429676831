import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RegistrationForm from '../../components/RegistrationForm';
import Splash from '../../components/Splash';
import useMediaQuery from '../../hooks/useMediaQuery';
import SellingPoint from './SellingPoint';
import { useStyles } from './styles';

export default function Register(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const [userHasRegistered, setUserHasRegistered] = React.useState(false);

  function onUserRegisteredSuccessfully() {
    setUserHasRegistered(true);
  }

  return (
    <Splash>
      <div className={classes.container}>
        {userHasRegistered ? (
          <Typography variant="h2" textAlign="center">
            {t('account:register.success')}
          </Typography>
        ) : (
          <>
            {greaterThanSm && (
              <div className={`${classes.marketing} ${classes.column}`}>
                <div className={classes.header}>
                  <Typography variant="h1">{t('account:register.heading')}</Typography>
                  <Typography variant="h4">{t('account:register.subHeading')}</Typography>
                </div>
                <div className={classes.marketingWidgets}>
                  <SellingPoint
                    title={t('account:register.sellingPoint1.title')}
                    text={t('account:register.sellingPoint1.text')}
                  />
                  <SellingPoint
                    title={t('account:register.sellingPoint2.title')}
                    text={t('account:register.sellingPoint2.text')}
                  />
                  <SellingPoint
                    title={t('account:register.sellingPoint3.title')}
                    text={t('account:register.sellingPoint3.text')}
                  />
                </div>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('account:register.footer')}
                </Typography>
              </div>
            )}
            <Box className={`${classes.formBox} ${classes.column}`}>
              <RegistrationForm onUserRegisteredSuccessfully={onUserRegisteredSuccessfully} />
            </Box>
          </>
        )}
      </div>
    </Splash>
  );
}
