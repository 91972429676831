import { useQuery } from '@apollo/client';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import React, { useState } from 'react';
import { Namespace, Resources, TFunction, useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OWNED_ASSET_INSTANCES_QUERY } from '../../graphql/queries/ownedAssetInstance';
import { formatter } from '../../utils/currencyFormatter';
import { humanReadableDate } from '../../utils/humanReadableDate';
import SubTable from '../Table/SubTable';
import { Headers } from '../Table/types';
import TransferAssetModal from '../TransferAssetModal';
import Typography from '../Typography';
import Pill from '../Pill';
import { TableData } from './types';
import { useSelectedRowsContext } from '../../context/selectedRowsContext';
import { useLastIndexAvailableForOffer } from '../../hooks/useLastIndexAvailableForOffer';
import { graphql } from '../../__generated__';
import { AssetInstance, OwnedAssetInstancesQuery } from '../../__generated__/graphql';
import { TableRefactorFlagSet } from '../../types/FeatureFlags';
import CultXPortfolioSubTable from '../CultXPortfolioTable/SubTable';

export const PARENT_ASSET_QUERY = graphql(`
  query ParentAsset($assetId: Int!) {
    productAsset(assetId: $assetId) {
      id
      unitCount
      unitSize
      vintage {
        wine {
          name
        }
      }
    }
  }
`);

interface SelectTableDataProps {
  rawData: OwnedAssetInstancesQuery | undefined;
  t: TFunction<Namespace<keyof Resources>>;
  transferAssetsOutOfCultX: boolean;
  lastIndexAvailableForOffer: number;
}
function selectTableData({
  rawData,
  t,
  transferAssetsOutOfCultX,
  lastIndexAvailableForOffer,
}: SelectTableDataProps): TableData[] {
  if (isUndefined(rawData)) {
    return [];
  }

  return rawData.productAssetInstances.map((rawInstance, index) => ({
    assetId: rawInstance.assetId,
    location: {
      content: (
        <Typography customVariant="subtitle2" style={{ textTransform: 'capitalize' }}>
          {rawInstance.location}
        </Typography>
      ),
    },
    purchasePrice: { mainContent: formatter.format(rawInstance.purchasePrice) },
    marketValuation: {
      mainContent: rawInstance.valuation ? formatter.format(rawInstance.valuation) : t('product:notAvailable'),
    },
    duty: {
      content: (
        <Typography customVariant="subtitle2" style={{ textTransform: 'capitalize' }}>
          {rawInstance.bondedStatus}
        </Typography>
      ),
    },
    entryDate: { mainContent: humanReadableDate(rawInstance.entryDate) },
    status: {
      content:
        transferAssetsOutOfCultX && rawInstance.transferRequested ? (
          <Pill colour="#FB9D0E" text={t('portfolio:transfer.requested')} />
        ) : (
          <></>
        ),
    },
    transferRequested: isNull(rawInstance.transferRequested) ? undefined : rawInstance.transferRequested,
    transferDisabled: rawInstance.transferRequested || index > lastIndexAvailableForOffer,
    rowId: rawInstance.id,
  }));
}

interface AssetInstanceTableProps {
  assetId: number;
}

export default function AssetInstanceTable({ assetId }: AssetInstanceTableProps): JSX.Element {
  const { t } = useTranslation();
  const { transferAssetsOutOfCultX } = useFlags();
  const { tableRefactor } = useFlags<{ tableRefactor?: TableRefactorFlagSet }>();
  const { dispatch } = useSelectedRowsContext();
  const [modalOpen, setModalOpen] = useState(false);
  const { data: parentAsset } = useQuery(PARENT_ASSET_QUERY, {
    variables: { assetId },
  });
  const { loading, data, error } = useQuery(OWNED_ASSET_INSTANCES_QUERY, {
    variables: { assetId },
  });
  const { lastIndexAvailableForOffer } = useLastIndexAvailableForOffer({ assetId });

  function handleTransferSubCheckboxChanged(subRowId: string, currentAssetId: number | undefined): void {
    const row: AssetInstance | undefined = data?.productAssetInstances.find((x) => x.id.toString() === subRowId);
    const currentLocation = row?.location;
    const purchasedPrice = row?.purchasePrice;
    const unitCount = parentAsset?.productAsset?.unitCount;
    const unitSize = parentAsset?.productAsset?.unitSize;
    dispatch({
      type: 'rowTransferChecked',
      payload: {
        type: 'AssetInstance',
        id: subRowId,
        assetId: currentAssetId,
        currentLocation,
        purchasedPrice,
        wineName: parentAsset?.productAsset?.vintage.wine.name,
        unitSize: `${unitCount} x ${unitSize}`,
        marketValue: row?.valuation,
      },
    });
  }

  const tableData = selectTableData({
    rawData: data,
    t,
    transferAssetsOutOfCultX,
    lastIndexAvailableForOffer,
  });
  const headers: Headers<Omit<TableData, 'rowId'>> = {
    location: { title: t('portfolio:table.headers.location') },
    purchasePrice: { title: t('portfolio:table.headers.purchasePrice') },
    marketValuation: { title: t('portfolio:table.headers.marketValuation') },
    duty: { title: t('portfolio:table.headers.duty') },
    entryDate: { title: t('portfolio:table.headers.entryDate') },
    status: { title: '' },
  };

  const TableComponent = tableRefactor?.externalPortfolio ? (
    <CultXPortfolioSubTable
      instances={data?.productAssetInstances}
      lastIndexAvailableForOffer={lastIndexAvailableForOffer}
      loading={loading}
      error={error?.message ?? null}
      onTransferCheckboxChanged={handleTransferSubCheckboxChanged}
    />
  ) : (
    <SubTable
      loading={loading}
      rows={tableData}
      headers={headers}
      emptyTableText={t('common:empty')}
      error={error?.message ?? null}
      handleTransferSubCheckboxChanged={handleTransferSubCheckboxChanged}
    />
  );

  return (
    <>
      {TableComponent}
      {modalOpen && <TransferAssetModal assetId={assetId} open={modalOpen} onClose={() => setModalOpen(false)} />}
    </>
  );
}
