import React from 'react';
import TableCell from '@mui/material/TableCell';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '../../Typography';
import { isNull } from '../../../utils/isNull';
import { ScoreCell as ScoreCellProps } from '../types';

const useStyles = makeStyles(() => ({
  oneLine: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));

export default function ScoreCell({ score }: ScoreCellProps): JSX.Element {
  const classes = useStyles();
  return (
    <TableCell>
      <div className={classes.oneLine}>
        <Typography variant="subtitle2">{isNull(score) ? '-' : score}</Typography>
        <Typography variant="body2" zellarColour="textInformation">
          /100
        </Typography>
      </div>
    </TableCell>
  );
}
