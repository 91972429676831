import makeStyles from '@mui/styles/makeStyles';

export const useLoginFormStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
    textAlign: 'center',
  },
  forgotPasswordLink: {
    textAlign: 'left',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(3),
  },
  label: {
    alignSelf: 'flex-start',
  },
}));
