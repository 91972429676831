import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '../Typography';
import Divider from '../Divider';

const useStyles = makeStyles(() => ({
  container: {
    padding: '10px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  title: {
    marginLeft: '4px',
  },
}));

interface SuggestionGroupHeaderProps {
  title: string;
  className?: string;
}

export default function SuggestionGroupHeader({ title, className = '' }: SuggestionGroupHeaderProps): JSX.Element {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${className}`}>
      <Typography className={classes.title} customVariant="subtitle2">
        {title}
      </Typography>
      <Divider />
    </div>
  );
}
