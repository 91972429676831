import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import SuggestedSearches from '../../components/SuggestedSearches';
import Divider from '../../components/Divider';
import Typography from '../../components/Typography';
import SearchInput from '../../components/SearchInput';
import { useSearchBar } from '../../hooks/useSearchBar';
import { useQueryParameters } from '../../hooks';
import useMediaQuery from '../../hooks/useMediaQuery';
import ActiveMarkets from './components/ActiveMarkets';

const useStyles = makeStyles((theme) => ({
  leftColumn: {
    [theme.breakpoints.up('lg')]: {
      /**
       * Emulate the divider, it's an absolute
       * nightmare trying to get <Divider /> to
       * work with <Grid /> without completely
       * messing up everything we have so far.
       */
      borderWidth: '0 1px 0 0', // right side
      borderColor: theme.palette.dividers.primary,
      borderStyle: 'solid',
    },
  },
  articlesContainer: {
    padding: theme.spacing(7),
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  suggestedSearches: {
    marginBottom: theme.spacing(4),
  },
  separator: {
    border: 'none',
    margin: 0,
    flexShrink: 0,
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      marginBottom: 10,
      height: 1,
      borderLeft: 0,
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      left: 0,
    },
  },
  search: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
  },
  searchRoot: {
    width: '75%',
  },
  searchInputRoot: {
    width: '100%',
    borderRadius: '35px',
    background: theme.palette.grey[50],
    padding: `${theme.spacing(0.25)} ${theme.spacing(4)}`,
    height: 'auto',
    fontSize: theme.typography.subtitle1.fontSize,
  },
}));

export default function Discover(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { searchBreakingChanges, desktopOnly } = useFlags();
  const query = useQueryParameters();
  const searchTermQueryParameter = query.get('term');
  const theme = useTheme();
  const { activeMarket } = useFlags();
  const greaterThanMd = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md));
  const {
    searchBarActive,
    handleAppellationSuggestionSelected,
    handleProducerSuggestionSelected,
    handleRegionSelected,
    handleSearchTermSubmitted,
    handleSearchValueChanged,
    handleWineVintageSelected,
    searchInputValue,
    handleClickAway,
    handleSearchBarFocus,
    handleClearSearchInput,
  } = useSearchBar({ initialSearchTerm: searchTermQueryParameter ?? undefined });

  return (
    <Grid spacing={4} container>
      <Grid xs={12} spacing={4} item container>
        {searchBreakingChanges && (
          <>
            {!desktopOnly && (
              <div className={classes.search}>
                {greaterThanMd && <Typography variant="h1">{t('overview:search')}</Typography>}
                <SearchInput
                  active={searchBarActive}
                  onFocus={handleSearchBarFocus}
                  onClickAway={handleClickAway}
                  inputValue={searchInputValue}
                  onChange={handleSearchValueChanged}
                  onClearInput={handleClearSearchInput}
                  searchTermSubmitted={handleSearchTermSubmitted}
                  placeholder={t('overview:searchPlaceholder')}
                  classes={{ root: classes.searchRoot, searchBarRoot: classes.searchInputRoot }}
                  wineVintageSelected={handleWineVintageSelected}
                  regionSelected={handleRegionSelected}
                  appellationSelected={handleAppellationSuggestionSelected}
                  producerSelected={handleProducerSuggestionSelected}
                />
              </div>
            )}
            <Grid item xs={12}>
              <SuggestedSearches className={classes.suggestedSearches} />
              <Divider variant="fullWidth" />
            </Grid>
            {activeMarket && (
              <Grid item xs={12}>
                <ActiveMarkets />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
