import { useQuery } from '@apollo/client';
import { SelectOption } from '@cultwines/zellar-client-sdk';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { GraphQLError } from 'graphql';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RRLink, useHistory, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import WineStubImage from '../../assets/images/stub-wine-image.png';
import { EXTERNAL_PORTFOLIO_USER_HAS_ASSET } from '../../graphql/queries/externalPortfolioUserHasAsset';
import { PORTFOLIO_USER_HAS_ASSET } from '../../graphql/queries/portfolioUserHasAsset';
import { selectErrorMessage } from '../../graphql/selectors/selectErrorMessage';
import { useCalculatedMarketData } from '../../hooks/useCalculatedMarketData';
import useDoesAssetExistInAnyHoldings from '../../hooks/useDoesAssetExistInAnyHoldings';
import useMediaQuery from '../../hooks/useMediaQuery';
import { colours } from '../../theme/light/palette';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { massageToNull } from '../../utils/massageToNull';
import { PortfolioTabs } from '../../views/Portfolio';
import { selectLiquidityScore } from '../../views/Search/selectors';
import ErrorPlaceholder from '../ErrorPlaceholder';
import UnitSizeSelect from '../Select/UnitSizeSelect';
import VintageSelectUncontrolled from '../Select/VintageSelectUncontrolled';
import TransferAssetToExternalPortfolioModal from '../TransferAssetToExternalPortfolioModal/PreselectedAsset';
import Typography from '../Typography';
import WatchlistButton from '../WatchlistButton';
import Badge from './Badge';
import MarketPricePercentageChange from './MarketPricePercentageChange';
import OptionsMenu from './OptionsMenu';
import { PRODUCT_DATA_WATCHLIST_QUERY } from './queries';
import Scores from './Scores';
import { selectProductSummary, selectVintages } from './selectors';
import ProductSummarySkeleton from './Skeleton';
import useStyles from './styles';
import CalculationToolTip from '../CalculationToolTip';
import SocialShareButton from '../SocialShare/SocialShareButton';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { usePercentageDifference } from '../../hooks/usePercentageDifference';
import calculateDateRange from '../../utils/calculateDateRange';

interface Props {
  assetId: number;
  description: string;
}

enum ChangeTypeEnum {
  UNIT_SIZE = 'unit_size',
  VINTAGE = 'vintage',
}

const removeWhitespce = (str: string) => str.replace(/[ ,]/g, '').toLowerCase().trim();

export default function ProductSummary({ assetId, description }: Props): JSX.Element {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { enableSharePageFeature } = useFlags();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentVintageId, setCurrentVintageId] = useState<number | null>(null);
  const [refetchingUnitSize, setRefetchingUnitSize] = useState(false);
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();
  const { data: doesAssetExistInAnyHoldings } = useDoesAssetExistInAnyHoldings({ assetId });
  const { data: isInExternalPortfolio } = useQuery(EXTERNAL_PORTFOLIO_USER_HAS_ASSET, {
    variables: {
      assetId,
    },
    pollInterval: 4000,
  });
  const { data: isInPortfolio } = useQuery(PORTFOLIO_USER_HAS_ASSET, {
    variables: {
      assetId,
    },
  });
  const { data, loading, error, refetch } = useQuery(PRODUCT_DATA_WATCHLIST_QUERY, {
    variables: {
      assetId,
    },
  });

  async function handleReloadProductData(): Promise<void> {
    await refetch({ assetId });
  }

  const { percentageDifference } = usePercentageDifference({ assetId, dateRange: calculateDateRange('1Y') });
  const calcDataResponse = useCalculatedMarketData({ assetId });
  const liquidityScore = selectLiquidityScore(
    massageToNull(calcDataResponse.data?.calculatedMarketData?.liquidityScore),
  );

  const vintages = selectVintages(data?.productAsset, assetId);
  const selectedUnitSize = state?.unitSize ? removeWhitespce(`${state.unitSize}`) : null;

  const onUpdateUnitSize = useCallback(
    (options: SelectOption<string>[]) => {
      return options.find((size) => removeWhitespce(size.label) === selectedUnitSize);
    },
    [selectedUnitSize],
  );

  useEffect(() => {
    if (!loading && currentVintageId) {
      refetch({ assetId: currentVintageId }).then((response) => {
        const { vintage, vintages: _vintages } = response.data?.productAsset!;
        const assets = _vintages.find((x) => x.id === vintage.id)?.assets;
        const selectedAsset = assets?.find((x) => {
          return removeWhitespce(`${x.unitCount}x${x.unitSize}`) === selectedUnitSize;
        });
        setRefetchingUnitSize(false);
        history.push(`/product/${selectedAsset?.id ?? currentVintageId}`, {
          unitSize: state?.unitSize,
          changeType: ChangeTypeEnum.VINTAGE,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVintageId]);

  if (loading || refetchingUnitSize) {
    return <ProductSummarySkeleton />;
  }

  if (error || !data?.productAsset) {
    return (
      <ErrorPlaceholder
        error={error ? error.message : t('product:failedToLoadSummaryData')}
        action={
          <Button
            className={classes.retryButton}
            variant="contained"
            onClick={handleReloadProductData}
            disabled={loading}
          >
            {t('common:retry')}
          </Button>
        }
      />
    );
  }
  const normalisedProductData = { ...selectProductSummary(data?.productAsset), ...data?.productAsset };

  function afterUnitSizeChange(option: SelectOption) {
    if (option.value) {
      history.replace(`/product/${option.value}`, { unitSize: option.label, changeType: ChangeTypeEnum.UNIT_SIZE });
    }
  }

  function handleAddToWatchlist(): void {
    enqueueSnackbar(t('product:watchList.addedMessage'), { variant: 'info' });
  }

  function handleRemoveFromWatchlist(): void {
    enqueueSnackbar(t('product:watchList.deletedMessage'), { variant: 'info' });
  }

  function handleWatchlistButtonError(errors: readonly GraphQLError[]): void {
    enqueueSnackbar(selectErrorMessage(errors), { variant: 'error' });
  }

  function handleTransferRequested(): void {
    setModalOpen(true);
  }

  function handleVintageChanged(optionId: number) {
    setCurrentVintageId(optionId);
    setRefetchingUnitSize(true);
  }

  return (
    <div className={classes.summaryDetails}>
      <img
        src={normalisedProductData.imageUrl || WineStubImage}
        className={classes.image}
        data-cy="wine-image"
        alt={t('product:wineImageAltTag')}
      />
      <div className={classes.details}>
        <div className={classes.actions}>
          <div className={classes.badgesContainer}>
            {!!isInExternalPortfolio?.externalPortfolioUserHasAsset && (
              <Link
                component={RRLink}
                to={`/portfolio?tab=${PortfolioTabs.ExternalPortfolio}`}
                className={classes.link}
              >
                <Badge label={t('portfolio:externalPortfolio.partOfYourPortfolio')} />
              </Link>
            )}
            {!!isInPortfolio?.isUserHoldingHasAsset && (
              <Link component={RRLink} to={`/portfolio?tab=${PortfolioTabs.CultXPortfolio}`} className={classes.link}>
                <Badge colour={theme.palette.card.accent} label={t('portfolio:partOfYourPortfolio')} />
              </Link>
            )}
            {doesAssetExistInAnyHoldings?.isAssetExistInAnyHoldings && (
              <Badge colour={colours.cultYellow} label={t('product:communityHolding')} />
            )}
          </div>
          <div className={classes.rightSideActions}>
            <WatchlistButton
              assetId={assetId}
              onAdd={handleAddToWatchlist}
              onRemove={handleRemoveFromWatchlist}
              onError={handleWatchlistButtonError}
            />
            <OptionsMenu assetId={assetId} handleTransferRequested={handleTransferRequested} />
          </div>
        </div>
        <Typography variant="h2">{normalisedProductData.displayName}</Typography>
        {greaterThanSm && (
          <Typography variant="body1" zellarColour="textLabel">
            {description}
          </Typography>
        )}
        <div className={classes.selects}>
          <UnitSizeSelect
            assetId={assetId}
            onUpdateUnitSize={selectedUnitSize ? onUpdateUnitSize : undefined}
            afterUnitSizeChange={afterUnitSizeChange}
          />
          {!loading && (
            <>
              <VintageSelectUncontrolled
                options={vintages}
                onChange={handleVintageChanged}
                selectedOptionId={assetId}
                loading={loading}
              />

              {enableSharePageFeature && (
                <SocialShareButton productId={`${assetId}`} title={`${normalisedProductData.displayName}`} />
              )}
            </>
          )}
        </div>
        <div className={classes.horizontalContainer}>
          <div className={classes.marketValueContainer}>
            <Typography className={classes.uppercase} customVariant="label1" zellarColour="textLabel">
              {t('product:marketValue')}
              <CalculationToolTip title={t('market:marketValueCalculation')} />
            </Typography>
            <div className={classes.marketValue}>
              <Typography customVariant="h3">
                {!isNullOrUndefined(normalisedProductData.marketValue)
                  ? formatterWholeNumber.format(normalisedProductData.marketValue)
                  : '--'}
              </Typography>
              <MarketPricePercentageChange value={percentageDifference || undefined} />
            </div>
          </div>
          <Scores
            criticScore={calcDataResponse?.data?.calculatedMarketData.combinedScore}
            liquidtyScore={liquidityScore}
          />
        </div>
      </div>
      {modalOpen && (
        <TransferAssetToExternalPortfolioModal assetId={assetId} open={modalOpen} onClose={() => setModalOpen(false)} />
      )}
    </div>
  );
}
