import { Order } from '../../types';
import { OfferForAssetQuery } from '../../__generated__/graphql';

export function selectLowestOffer(arg: OfferForAssetQuery['productAsset'] | null | undefined): Order | null {
  if (arg && arg.spread && arg.spread.lowestOffer && arg.spread.lowestOffer) {
    return {
      price: arg.spread.lowestOffer.price,
      userId: arg.spread.lowestOffer.userId,
    };
  }

  return null;
}

export function selectUserId(arg: { userId: string } | undefined): string | null {
  return arg?.userId || null;
}
