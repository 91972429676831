import React from 'react';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import makeStyles from '@mui/styles/makeStyles';
import NavigationBar from '../NavigationBar';
import AppBar, { AppBarProps as AppBarPropsBase } from '../AppBar';
import KycPrompt from './kycPrompt';
import { getKycStatus } from '../../services/auth';
import { AppLayoutContextProvider, useAppLayoutContext } from './Context';
import Banner, { BANNER_HEIGHT } from './Banner';

const NAVBAR_WIDTH = 84;
const APPBAR_HEIGHT = 64;

const useStyles = makeStyles((theme) => ({
  navbar: {
    display: 'none',
    '@media (min-width: 768px) and (min-height: 750px)': {
      display: 'flex',
      flexDirection: 'column',
      width: `${NAVBAR_WIDTH}px`,
      height: '100vh',
    },
  },

  wrapperInner: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    height: '100vh',
    // For webkit browsers on iOS where the bottom address bar is not factored into vh unit.
    // Problem described here https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    // & here https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/12132
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    height: '-webkit-fill-available',
  },

  inner: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '@media (min-width: 768px) and (min-height: 750px)': {
      width: `calc(100% - ${NAVBAR_WIDTH}px)`,
    },
  },

  content: {
    height: `calc(100vh - ${APPBAR_HEIGHT + BANNER_HEIGHT}px)`,
    overflowY: 'scroll',
    backgroundColor: 'white',
    padding: '20px',
    /*
      FIXME: this is the cause of 11293 and 10790, I am leaving it here
      with this comment as a warning to anyone who tries to reinstate it.
    */
    /* border-bottom-left-radius: 20px; */

    '@media (min-width: 525px) and (min-height: 500px)': {
      height: `calc(100vh - ${APPBAR_HEIGHT}px)`,
    },
  },

  background: {
    display: 'none',

    '@media (min-width: 768px) and (min-height: 750px)': {
      display: 'block',
      position: 'absolute',
      height: '100vh',
      width: '150px',
      backgroundColor: theme.palette.card.background.dark,
      zIndex: -1,
    },
  },
}));

interface AppLayoutProps {
  children: React.ReactNode;
  AppBarProps?: AppBarPropsBase;
}

function AppLayout({ children, AppBarProps }: AppLayoutProps): JSX.Element {
  const classes = useStyles();
  const { state, dispatch } = useAppLayoutContext();
  const location = useLocation();
  const { kyc: kycFlag, desktopOnly } = useFlags();

  React.useEffect(() => {
    if (!desktopOnly) {
      dispatch({ type: 'appLayout/scrollTo', payload: { x: 0, y: 0 } });
    }
  }, [dispatch, location, desktopOnly]);

  return (
    <div className={classes.wrapperInner}>
      <NavigationBar className={classes.navbar} />
      {kycFlag && <KycPrompt kycStatus={getKycStatus()} />}
      <div className={classes.background} />
      <div className={classes.inner}>
        <Banner />
        <AppBar title={AppBarProps?.title} displayBackButton={AppBarProps?.displayBackButton} />
        <div className={classes.content} ref={state._ref}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default function AppLayoutWithContext(props: AppLayoutProps): JSX.Element {
  return (
    <AppLayoutContextProvider>
      <AppLayout {...props} />
    </AppLayoutContextProvider>
  );
}
