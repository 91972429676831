/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { Namespace, Resources, TFunction } from 'react-i18next';
import { GraphQLError } from 'graphql';
import {
  BasicCell,
  CustomCell,
  Row,
  SortDirection,
  UnitSizeCell,
  WineNameCell,
} from '../../../../components/Table/types';
import { formatterWholeNumber } from '../../../../utils/currencyFormatter';
import TradingTile from '../../../../components/TradingTile';
import { SortOption } from '../../../../components/Table/SortMenu';
import { uuid } from '../../../../utils/uuid';
import { ActiveMarketSortObject } from './types';
import { isNullOrUndefined } from '../../../../utils/isNullOrUndefined';
import { ActiveMarket } from '../../../../__generated__/graphql';

export interface TableData extends Row {
  wineName: WineNameCell;
  region: BasicCell;
  vintage: BasicCell;
  unitSize: UnitSizeCell;
  marketValue: BasicCell;
  highestBid: CustomCell;
  lowestOffer: CustomCell;
  spread: BasicCell;
}

interface SelectTableDataArgs {
  rawData: ActiveMarket[];
  handleAddToWatchlist: () => void;
  handleRemoveFromWatchlist: () => void;
  handleWatchlistError: (errors: readonly GraphQLError[]) => void;
  handleOpenBidModalClicked: (rowId: string) => void;
  handleOpenBuyNowModalClicked: (rowId: string) => void;
  handleOpenOfferModalClicked: (rowId: string) => void;
  clickableTradeTiles13816?: boolean;
}

export function selectTableData({
  handleAddToWatchlist,
  handleRemoveFromWatchlist,
  handleWatchlistError,
  handleOpenBidModalClicked,
  handleOpenBuyNowModalClicked,
  handleOpenOfferModalClicked,
  rawData,
  clickableTradeTiles13816,
}: SelectTableDataArgs): TableData[] {
  return rawData.map((asset) => {
    const HighestBidTradingTile = clickableTradeTiles13816 ? (
      <TradingTile
        mode="bid"
        value={asset.highestBid.price}
        onClick={() => handleOpenBidModalClicked(asset.assetId.toString())}
      />
    ) : (
      <TradingTile
        mode="bid"
        value={asset.highestBid.price}
        createdDate={
          isNullOrUndefined(asset.highestBid.createdDate)
            ? ''
            : new Date(asset.highestBid.createdDate).toLocaleDateString()
        }
      />
    );

    const LowestOfferTradingTile = clickableTradeTiles13816 ? (
      <TradingTile
        mode="offer"
        value={asset.lowestOffer.price}
        onClick={
          !isUndefined(asset.lowestOffer.price)
            ? () => handleOpenBuyNowModalClicked(asset.assetId.toString())
            : undefined
        }
      />
    ) : (
      <TradingTile
        mode="offer"
        value={asset.lowestOffer.price ?? 0}
        createdDate={
          isNullOrUndefined(asset.lowestOffer.createdDate)
            ? ''
            : new Date(asset.lowestOffer.createdDate).toLocaleDateString()
        }
      />
    );

    return {
      rowId: asset.assetId.toString(),
      wineName: {
        wineName: asset.wineName,
        region: asset.region,
        link: `/product/${asset.assetId}`,
        appellation: null,
      },
      region: {
        mainContent: asset.region,
      },
      vintage: {
        mainContent: asset.vintage,
      },
      marketValue: {
        mainContent: formatterWholeNumber.format(asset.marketValue),
      },
      highestBid: {
        content: HighestBidTradingTile,
      },
      lowestOffer: {
        content: LowestOfferTradingTile,
      },
      spread: {
        mainContent: isNull(asset.spread) ? '-' : `${asset.spread}%`,
      },
      unitSize: {
        unitCount: asset.unitCount,
        unitSize: asset.unitSize,
      },
      actions: {
        watchlist: {
          onAdd: handleAddToWatchlist,
          onRemove: handleRemoveFromWatchlist,
          onError: handleWatchlistError,
        },
        trade: {
          assetId: asset.assetId,
          condensed: true,
          onBidClicked: handleOpenBidModalClicked,
          onBuyClicked: handleOpenBuyNowModalClicked,
          onOfferClicked: handleOpenOfferModalClicked,
        },
      },
    };
  });
}

export function selectSortOptions(
  sortDirection: SortDirection,
  activeSortKey: keyof ActiveMarketSortObject,
  t: TFunction<Namespace<keyof Resources>>,
): SortOption<keyof ActiveMarketSortObject>[] {
  const sortOptions: SortOption<keyof ActiveMarketSortObject>[] = [
    {
      // FIXME: These ids are recreated on each render, which is triggered by changing sort/filter/page.
      // https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/12699
      id: uuid(),
      direction: 'desc',
      key: 'BestBids',
      active: activeSortKey === 'BestBids',
      primaryLabel: t('discover:activeMarkets.sortOptions.bestBids'),
      secondaryLabel: t('search:sort.bestFirst'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'BestOffers',
      active: activeSortKey === 'BestOffers',
      primaryLabel: t('discover:activeMarkets.sortOptions.bestOffers'),
      secondaryLabel: t('search:sort.bestFirst'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'LatestBids',
      active: activeSortKey === 'LatestBids',
      primaryLabel: t('discover:activeMarkets.sortOptions.latestBids'),
      secondaryLabel: t('search:sort.newestToOldest'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'LatestOffers',
      active: activeSortKey === 'LatestOffers',
      primaryLabel: t('discover:activeMarkets.sortOptions.latestOffers'),
      secondaryLabel: t('search:sort.newestToOldest'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'LowestOffer',
      active: activeSortKey === 'LowestOffer' && sortDirection === 'asc',
      primaryLabel: t('discover:activeMarkets.sortOptions.lowestOffer'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'LowestOffer',
      active: activeSortKey === 'LowestOffer' && sortDirection === 'desc',
      primaryLabel: t('discover:activeMarkets.sortOptions.lowestOffer'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'HighestBid',
      active: activeSortKey === 'HighestBid' && sortDirection === 'desc',
      primaryLabel: t('discover:activeMarkets.sortOptions.highestBid'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'HighestBid',
      active: activeSortKey === 'HighestBid' && sortDirection === 'asc',
      primaryLabel: t('discover:activeMarkets.sortOptions.highestBid'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },

    {
      id: uuid(),
      direction: 'desc',
      key: 'WineName',
      active: activeSortKey === 'WineName' && sortDirection === 'desc',
      primaryLabel: t('common:wineName'),
      secondaryLabel: t('search:sort.zToA'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'WineName',
      active: activeSortKey === 'WineName' && sortDirection === 'asc',
      primaryLabel: t('common:wineName'),
      secondaryLabel: t('search:sort.aToZ'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'Vintage',
      active: activeSortKey === 'Vintage' && sortDirection === 'desc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.newestToOldest'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'Vintage',
      active: activeSortKey === 'Vintage' && sortDirection === 'asc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.oldestToNewest'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'MarketValue',
      active: activeSortKey === 'MarketValue' && sortDirection === 'desc',
      primaryLabel: t('search:filters.marketValue'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'MarketValue',
      active: activeSortKey === 'MarketValue' && sortDirection === 'asc',
      primaryLabel: t('search:filters.marketValue'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },

    {
      id: uuid(),
      direction: 'desc',
      key: 'Region',
      active: activeSortKey === 'Region' && sortDirection === 'desc',
      primaryLabel: t('common:region'),
      secondaryLabel: t('search:sort.zToA'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'Region',
      active: activeSortKey === 'Region' && sortDirection === 'asc',
      primaryLabel: t('common:region'),
      secondaryLabel: t('search:sort.aToZ'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'UnitSize',
      active: activeSortKey === 'UnitSize' && sortDirection === 'desc',
      primaryLabel: t('discover:activeMarkets.sortOptions.unitSize'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'UnitSize',
      active: activeSortKey === 'UnitSize' && sortDirection === 'asc',
      primaryLabel: t('discover:activeMarkets.sortOptions.unitSize'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
  ];

  return sortOptions;
}
