import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import CircleCheckOutlineIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { isNull } from '../../utils/isNull';
import { logError } from '../../utils/logger';
import TradingCard from '../TradingCard';
import TradingCardSkeleton from '../TradingCard/Skeleton';
import { selectLowestOffer, selectUserId } from './selectors';
import { graphql } from '../../__generated__';
import { Asset } from '../../__generated__/graphql';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.card.accent,
    stroke: theme.palette.card.accent,
    width: theme.spacing(4),
    height: theme.spacing(4),
    strokeWidth: '0.5px',
  },
}));

export const GET_OFFER_FOR_ASSET_QUERY = graphql(`
  query OfferForAsset($assetId: Int!) {
    productAsset(assetId: $assetId) {
      id
      spread {
        lowestOffer {
          userId
          price
        }
      }
    }

    authUser {
      userId
    }
  }
`);

interface OfferCardProps {
  assetId: number;
  className?: string;
  onViewClick?: () => void;
  showButton?: boolean;
}

export default function OfferCard({
  className = '',
  assetId,
  onViewClick,
  showButton = false,
}: OfferCardProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_OFFER_FOR_ASSET_QUERY, {
    variables: {
      assetId,
    },
  });

  function handleClick() {
    if (onViewClick) {
      onViewClick();
    }
  }

  const lowestOffer = useMemo(() => selectLowestOffer(data?.productAsset as Asset), [data]);
  const currentUserId = useMemo(() => selectUserId(data?.authUser), [data]);

  if (loading) {
    return <TradingCardSkeleton className={className} />;
  }

  if (error) {
    logError({
      originalError: error,
      error: new Error('Failed to get offer'),
      filename: 'OfferCard',
      additionalInfo: {
        assetId,
      },
    });
    return <div>{error.message}</div>;
  }

  const Title =
    !isNull(lowestOffer) && !isNull(currentUserId) && lowestOffer.userId === currentUserId ? (
      <Typography variant="subtitle1">
        {t('product:trading.lowestOfferPersonal')} <CircleCheckOutlineIcon className={classes.icon} />
      </Typography>
    ) : (
      <Typography variant="subtitle1">{t('product:trading.lowestOffer')}</Typography>
    );

  return (
    <TradingCard
      className={className}
      title={Title}
      mode="offer"
      showButton={showButton}
      value={
        !isNull(lowestOffer) && !isNullOrUndefined(lowestOffer.price)
          ? formatterWholeNumber.format(lowestOffer.price)
          : '--'
      }
      actionText={t('product:trading.viewAllOffers')}
      onActionClicked={handleClick}
    />
  );
}
