import React from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { OrderDirection } from '../components/OrderBookModal/types';
import { OrderStatus } from '../types/OrderStatus';
import { isNullOrUndefined } from '../utils/isNullOrUndefined';
import { graphql } from '../__generated__';

export const CAN_CREATE_OFFER_QUERY = graphql(`
  query CanCreateOfferQuery($assetId: Int!) {
    omsGetMyOrders {
      id
      asset {
        id
      }
      direction
      outstandingQuantity
      status
    }
    productAssetInstances(assetId: $assetId) {
      id
      transferRequested
    }
  }
`);

export type UseCanCreateOfferReturnType = {
  canCreateOffer: boolean;
  loading: boolean;
  error?: ApolloError;
};

export default function useCanCreateOffer({ assetId }: { assetId: number }): UseCanCreateOfferReturnType {
  const [canCreateOffer, setCanCreateOffer] = React.useState(false);
  const { data, error, loading } = useQuery(CAN_CREATE_OFFER_QUERY, {
    variables: {
      assetId,
    },
    skip: isNullOrUndefined(assetId),
  });

  React.useEffect(() => {
    if (data) {
      const totalCasesInLiveOffers = data.omsGetMyOrders
        .filter(
          (order) =>
            order.direction === OrderDirection.Offers &&
            order.asset.id === assetId &&
            order.status === OrderStatus.Live,
        )
        .map((offer) => offer.outstandingQuantity)
        .reduce((prev, curr) => prev + curr, 0);

      // productAssetInstances will only be ones which the logged in user owns.
      setCanCreateOffer(totalCasesInLiveOffers < data.productAssetInstances.filter((a) => !a.transferRequested).length);
    } else {
      setCanCreateOffer(false);
    }
    // no need to include assetId, that'll trigger data to be changed anyway
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { canCreateOffer, loading, error };
}
