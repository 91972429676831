import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import LogoDarkBeta from '../../assets/images/logo-dark-beta.png';
import { ReactComponent as ArrowLeftCircled } from '../../assets/icons/arrow-left-circled.svg';
import { useSplashStyles } from './styles';
import { appIsWorkingVar } from '../../graphql/cache';
import Banner from '../AppLayout/Banner';

interface SplashProps {
  children: React.ReactNode;
  displayBackArrow?: boolean;
}

export default function Splash({ children, displayBackArrow = false }: SplashProps): JSX.Element {
  const classes = useSplashStyles();
  const history = useHistory();
  const appIsWorking = useReactiveVar(appIsWorkingVar);

  function handleBackButtonClick(): void {
    history.push('/login');
  }

  return (
    <>
      <Banner loginRoutes />
      <div className={classes.container}>
        <Backdrop className={classes.backdrop} open={appIsWorking}>
          <CircularProgress size={42} />
        </Backdrop>
        {displayBackArrow && (
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                onClick={handleBackButtonClick}
                className={classes.backButton}
                aria-label="back-button"
                size="large"
              >
                <ArrowLeftCircled />
              </IconButton>
            </Toolbar>
          </AppBar>
        )}
        <div className={classes.header}>
          <img src={LogoDarkBeta} alt="logo" />
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </>
  );
}
