import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RRLink } from 'react-router-dom';
import * as yup from 'yup';
import makeStyles from '@mui/styles/makeStyles';
import { billingCountrySelectOptions } from '@cultwines/zellar-client-sdk';
import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as CircledCrossIcon } from '../../assets/icons/circled-cross.svg';
import { selectErrorMessage } from '../../graphql/selectors/selectErrorMessage';
import Checkbox from '../Checkbox';
import { useRegistrationFormStyles } from './styles';
import { logError } from '../../utils/logger';
import Select from '../Select';
import { onboardingTheme } from '../../theme';
import { graphql } from '../../__generated__';
import { AuthRegisterUserInput } from '../../__generated__/graphql';

const linkStyles = makeStyles(() => ({
  link: {
    color: 'white',
    textDecoration: 'underline',
  },
}));

const unSelectedOption = { value: -999, label: '' };

export const REGISTER = graphql(`
  mutation Register($registerUserInput: AuthRegisterUserInput!) {
    authRegister(registerUserInput: $registerUserInput) {
      userId
    }
  }
`);

export default function RegistrationForm({
  onUserRegisteredSuccessfully,
}: {
  onUserRegisteredSuccessfully: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const classes = useRegistrationFormStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isResidentSet, setIsResidentSet] = useState(false);
  const [manageConsentExpanded, setManageConsentExpanded] = React.useState(false);
  const [register] = useMutation(REGISTER, {
    context: { serviceName: 'insecure' },
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });

  const registrationSchema = yup.object({
    firstName: yup.string().required(t('auth:firstName.required')),
    lastName: yup.string().required(t('auth:lastName.required')),
    countryOfResidenceId: yup.number(),
    countryOfResidence: yup
      .string()
      .test('countryOfResidence', t('auth:countryOfResidence.required'), (label) => label !== unSelectedOption.label)
      .required(t('auth:countryOfResidence.required')),
    emailAddress: yup.string().email(t('auth:emailAddress.required')).required(t('auth:emailAddress.required')),
    password: yup
      .string()
      .min(8, t('auth:password.invalid'))
      // eslint-disable-next-line no-useless-escape
      .matches(/[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g)
      .required(t('auth:password.required')),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], t('auth:passwordConfirmation.invalid'))
      .required(t('auth:passwordConfirmation.required')),
    tsAndCs: yup.boolean().oneOf([true], t('auth:termsAndConditions.required')),
    marketingPerefence: yup.boolean().required(),
    dateOfBirth: yup
      .string()
      .matches(/\d{4}-\d{2}-\d{2}/, t('account:accountDetails.dateOfBirth.invalidFormatRegsiter'))
      .required(t('account:accountDetails.dateOfBirth.required')),
    phoneNumber: yup
      .string()
      .min(7, t('account:accountDetails.phoneNumber.invalid'))
      .max(17, t('account:accountDetails.phoneNumber.invalid'))
      .optional(),
  });

  const linkClass = linkStyles();

  const formik = useFormik<yup.InferType<typeof registrationSchema>>({
    initialValues: {
      emailAddress: '',
      firstName: '',
      lastName: '',
      countryOfResidenceId: unSelectedOption.value,
      countryOfResidence: unSelectedOption.label,
      password: '',
      passwordConfirmation: '',
      tsAndCs: false,
      marketingPerefence: false,
      dateOfBirth: '',
      phoneNumber: '',
    },
    onSubmit: async (formData) => {
      const registerUserInput: AuthRegisterUserInput = {
        emailAddress: formData.emailAddress,
        firstName: formData.firstName,
        surname: formData.lastName,
        residenceCountry: formData.countryOfResidence,
        password: formData.password,
        passwordConfirmation: formData.passwordConfirmation,
        registeredByApp: 'Zellar',
        hasAgreedToMarketing: formData.marketingPerefence,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
        dateOfBirth: formData.dateOfBirth,
        phoneNumber: formData.phoneNumber,
      };

      const { errors } = await register({
        variables: {
          registerUserInput,
        },
      });
      if (errors) {
        logError({
          error: new Error('Registration error'),
          originalError: errors[0],
          filename: 'RegistrationForm',
          additionalInfo: {
            message: selectErrorMessage(errors),
            errors: JSON.stringify(errors),
          },
          tags: { userFlow: 'auth' },
        });
        setErrorMessage(selectErrorMessage(errors));
      } else {
        (window as any).dataLayer.push({
          event: 'registration_complete',
        });
        onUserRegisteredSuccessfully();
      }
    },
    validationSchema: registrationSchema,
  });

  // eslint-disable-next-line react/no-unstable-nested-components
  const ValidationErrorBreakdown = () => (
    <Alert className={classes.formErrorAlert} severity="error" icon={<CircledCrossIcon stroke="white" />}>
      {Object.values(formik.errors).map((formikError) => (
        <Typography key={formikError} variant="body2">
          - {formikError}
        </Typography>
      ))}
    </Alert>
  );

  const onCountryOfResidenceChange = (optionId: number) => {
    if (!isResidentSet) setIsResidentSet(true);
    const selectionOption = billingCountrySelectOptions.find((c) => c.value === optionId);
    formik.setFieldValue('countryOfResidence', selectionOption?.label);
    formik.setFieldValue('countryOfResidenceId', `${optionId}`);
  };

  const countries = useMemo(() => {
    if (!isResidentSet) return [unSelectedOption, ...billingCountrySelectOptions];
    return billingCountrySelectOptions;
  }, [isResidentSet]);

  const onRootConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('tsAndCs', event.target.checked);
    formik.setFieldValue('marketingPerefence', event.target.checked);
  };

  useEffect(() => {
    if (formik.touched.tsAndCs && Boolean(formik.errors.tsAndCs)) {
      setManageConsentExpanded(true);
    }
  }, [formik.errors.tsAndCs, formik.touched.tsAndCs]);

  return (
    <form onSubmit={formik.handleSubmit} className={classes.container}>
      <Typography variant="h1">{t('auth:registrationForm.title')}</Typography>
      {errorMessage && (
        <Alert icon={<CircledCrossIcon stroke="white" />} severity="error">
          {errorMessage}
        </Alert>
      )}
      {Object.values(formik.touched).length > 0 && Object.values(formik.errors).length > 0 && (
        <ValidationErrorBreakdown />
      )}
      <div className={classes.field}>
        <InputLabel className={classes.label} htmlFor="firstName">
          <Typography variant="subtitle1">{t('auth:firstName.label')}</Typography>
        </InputLabel>
        <TextField
          id="firstName"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          variant="outlined"
        />
      </div>
      <div className={classes.field}>
        <InputLabel className={classes.label} htmlFor="lastName">
          <Typography variant="subtitle1">{t('auth:lastName.label')}</Typography>
        </InputLabel>
        <TextField
          id="lastName"
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          variant="outlined"
        />
      </div>
      <div className={classes.field}>
        <InputLabel className={classes.label} htmlFor="countryOfResidence">
          <Typography variant="subtitle1">{t('auth:countryOfResidence.label')}</Typography>
        </InputLabel>
        <Select
          dataTestId="countryOfResidence"
          containerClassName={classes.selectContainer}
          inputClass={classes.select}
          options={countries}
          setOption={onCountryOfResidenceChange}
          optionId={Number(formik.values.countryOfResidenceId)}
          error={formik.touched.countryOfResidence && Boolean(formik.errors.countryOfResidence)}
        />
      </div>

      <div className={classes.field}>
        <InputLabel className={classes.label} htmlFor="dateOfBirth">
          <Typography variant="subtitle1">
            {t('account:accountDetails.dateOfBirth.label')} {t('account:accountDetails.dateOfBirth.registerFormat')}
          </Typography>
        </InputLabel>
        <TextField
          id="dateOfBirth"
          name="dateOfBirth"
          value={formik.values.dateOfBirth}
          onChange={formik.handleChange}
          error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
          variant="outlined"
        />
      </div>
      <div className={classes.field}>
        <InputLabel className={classes.label} htmlFor="phoneNumber">
          <Typography variant="subtitle1">{t('account:accountDetails.phoneNumber.label')}</Typography>
        </InputLabel>
        <TextField
          id="phoneNumber"
          name="phoneNumber"
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
          variant="outlined"
        />
      </div>

      <div className={classes.field}>
        <InputLabel className={classes.label} htmlFor="emailAddress">
          <Typography variant="subtitle1">{t('auth:emailAddress.label')}</Typography>
        </InputLabel>
        <TextField
          id="emailAddress"
          name="emailAddress"
          value={formik.values.emailAddress}
          onChange={formik.handleChange}
          error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
          variant="outlined"
        />
      </div>
      <div className={classes.field}>
        <InputLabel className={classes.label} htmlFor="password">
          <Typography variant="subtitle1">{t('auth:password.label')}</Typography>
        </InputLabel>
        <TextField
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          type="password"
          error={formik.touched.password && Boolean(formik.errors.password)}
          variant="outlined"
        />
      </div>
      <div className={classes.field}>
        <InputLabel className={classes.label} htmlFor="passwordConfirmation">
          <Typography variant="subtitle1">{t('auth:passwordConfirmation.label')}</Typography>
        </InputLabel>
        <TextField
          id="passwordConfirmation"
          name="passwordConfirmation"
          value={formik.values.passwordConfirmation}
          onChange={formik.handleChange}
          type="password"
          error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
          variant="outlined"
        />
      </div>
      <div className={classes.consentContainer}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" minHeight={48}>
          <InputLabel htmlFor="rootConsent">
            <Typography variant="body1" align="left" ml={2}>
              <Trans key="auth:termsAndConditions.label">
                I accept the website{' '}
                <a href="https://www.cultx.com/privacy" className={linkClass.link} target="_blank" rel="noreferrer">
                  Terms,
                </a>{' '}
                <a href="https://www.cultx.com/privacy" className={linkClass.link} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>{' '}
                and consent to receiving marketing communications from CultX
              </Trans>
            </Typography>
          </InputLabel>
          <Checkbox
            checked={formik.values.tsAndCs && formik.values.marketingPerefence}
            indeterminate={formik.values.tsAndCs !== formik.values.marketingPerefence}
            id="rootConsent"
            name="rootConsent"
            color="secondary"
            inputProps={{ 'aria-label': 'root-consent-checkbox' }}
            onChange={onRootConsentChange}
          />
        </Stack>
      </div>
      <div className={classes.consentContainer}>
        <Accordion
          expanded={manageConsentExpanded}
          sx={{
            bgcolor: onboardingTheme.palette.background.default,
          }}
          onChange={(event: React.SyntheticEvent, newExpanded: boolean) => setManageConsentExpanded(newExpanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: onboardingTheme.palette.text.primary }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body1">{t('auth:termsAndConditions.manageConsent')}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pr: 0 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <InputLabel htmlFor="tsAndCs">
                <Typography variant="body1" align="left">
                  <Trans key="auth:termsAndConditions.terms">
                    I accept the CultX{' '}
                    <a href="https://www.cultx.com/privacy" className={linkClass.link} target="_blank" rel="noreferrer">
                      Terms
                    </a>{' '}
                    and{' '}
                    <a href="https://www.cultx.com/privacy" className={linkClass.link} target="_blank" rel="noreferrer">
                      Privacy Policy
                    </a>{' '}
                    (required)
                  </Trans>
                </Typography>
              </InputLabel>
              <Checkbox
                value={formik.values.tsAndCs}
                checked={formik.values.tsAndCs}
                id="tsAndCs"
                name="tsAndCs"
                color="secondary"
                onChange={formik.handleChange}
                inputProps={{ 'aria-label': 'terms-checkbox' }}
                error={formik.touched.tsAndCs && Boolean(formik.errors.tsAndCs)}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <InputLabel htmlFor="marketingPerefence">
                <Typography variant="body1" align="left">
                  {t('auth:termsAndConditions.marketing')}
                </Typography>
              </InputLabel>
              <Checkbox
                value={formik.values.marketingPerefence}
                checked={formik.values.marketingPerefence}
                id="marketingPerefence"
                name="marketingPerefence"
                color="secondary"
                onChange={formik.handleChange}
                inputProps={{ 'aria-label': 'marketing-perefence-checkbox' }}
                error={formik.touched.marketingPerefence && Boolean(formik.errors.marketingPerefence)}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </div>
      <Button type="submit" disabled={formik.isSubmitting}>
        {t('common:submit')}
      </Button>
      <Typography variant="body1">
        <Trans key="auth:registrationForm.signInLink">
          <Link to="/login" component={RRLink} underline="always" color="textPrimary">
            Sign in
          </Link>{' '}
          to your CultX account
        </Trans>
      </Typography>
    </form>
  );
}
