import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colours } from '../../theme/light/palette';
import { RegionName } from '../../types';

export const useWineCardStyles = makeStyles<Theme, { region: RegionName }>((theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '4px',
    height: 'fit-content',
  },
  selectable: {
    cursor: 'pointer',
  },
  selected: {
    background: colours.cornsilk,
    borderLeft: `4px solid ${colours.brighterOrange}`,
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(4),
  },
  expandedContent: {
    background: theme.palette.grey[50],
    padding: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(12),
    rowGap: theme.spacing(6),
  },
  fixedHeightScrollable: {
    maxHeight: '300px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  imageContainer: {
    alignSelf: 'center',
    background: (props) => theme.palette.regions[props.region],
    width: '133px',
    height: '133px',
    minWidth: '133px',
    borderRadius: '100%',
    overflow: 'visible',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 40px',
    margin: '20px',
  },
  flex1: {
    flex: 1,
  },
  icon: {
    color: theme.palette.textPrimary,
    fontSize: theme.typography.h5.fontSize,
  },
  actions: {
    whiteSpace: 'nowrap',
    textAlign: 'end',
  },
  actionsInner: {
    maxWidth: '100%',
    flexWrap: 'wrap',
    display: 'flex',
    marginLeft: 'auto',
    justifyContent: 'flex-end',
    gap: '4px',
  },
  topRow: {
    justifyContent: 'space-between',
    gap: '0 !important',
  },
  marketValuePercentage: {
    whiteSpace: 'nowrap',
  },
  noWrap: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: theme.spacing(1.5),
    alignItems: 'center',
  },
  pill: {
    width: 'fit-content',
    textTransform: 'uppercase',
    borderRadius: '4px',
    padding: `0 ${theme.spacing(1.25)}`,
    backgroundColor: theme.palette.button.primary.background.main,
    color: theme.palette.button.primary.text.main,
    fontSize: theme.typography.caption.fontSize,

    '&:hover': {
      backgroundColor: theme.palette.button.primary.background.hover,
    },
  },
  titleRow: {
    display: 'flex',
    flexWrap: 'nowrap',
    columnGap: theme.spacing(3),
    alignItems: 'flex-start',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(10),
    rowGap: theme.spacing(4),
    alignItems: 'stretch',
  },
  totalMarketValue: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  marketValue: {
    marginLeft: 'auto',
  },
  score: {
    padding: theme.spacing(1),
    borderRadius: '4px',
    background: colours.lightBlue,
    width: 'fit-content',
  },
  expandButton: {
    padding: `${theme.spacing(1)} 0`,
    width: '100%',
    background: theme.palette.grey[50],
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    textDecoration: 'underline',
  },
  capitalise: {
    textTransform: 'capitalize',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  subtitle1Link: {
    ...theme.typography.subtitle1,
    textDecoration: 'none',
    color: theme.palette.textPrimary,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    opacity: 1,
    backgroundColor: 'transparent',
    border: 'none',
  },
  oneline: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: 2,
    columnGap: 3,
  },
  calculationbuttonTooltip: {
    marginLeft: 0,
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
}));
