import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formBox: {
    width: '100%',
    marginBottom: '20px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'stretch',
    justifyContent: 'space-evenly',
    boxShadow: 'none',

    [theme.breakpoints.up('sm')]: {
      border: `1px solid ${theme.palette.dividers.primary}`,
      borderRadius: 20,
      padding: '50px 30px',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '50px 80px',
    },
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    alignItems: 'center',
    width: '100%',

    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: '80%',
    },
  },
}));

export { useStyles };
