import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '../Typography';
import { ReactComponent as WineBottles } from '../../assets/icons/wine-bottles.svg';
import { WineVintageSuggestion } from '../../graphql/selectors/selectSearchWineVintagesSuggestions';

const useStyles = makeStyles((theme) => ({
  inner: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  iconContainer: {
    padding: 6,
    background: theme.palette.grey[50],
    borderRadius: 4,
  },
  icon: {
    width: '2rem',
    height: '2rem',
    stroke: theme.palette.textPrimary,
  },
  text: { whiteSpace: 'break-spaces' },
}));

interface SuggestionProps {
  suggestion: WineVintageSuggestion;
  onClick: (suggestion: WineVintageSuggestion) => void;
}
// TODO: highlight the search text.
// https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/7031
// https://v4.mui.com/components/autocomplete/#highlights
export default function WineSuggestion({ suggestion, onClick }: SuggestionProps): JSX.Element {
  const classes = useStyles();
  return (
    <MenuItem onClick={() => onClick(suggestion)} disableRipple>
      <div className={classes.inner}>
        <div className={classes.iconContainer}>
          <WineBottles className={classes.icon} />
        </div>
        <div>
          <Typography className={classes.text} customVariant="subtitle1">
            {suggestion.wineName} {suggestion.vintage}
          </Typography>
          <Typography customVariant="body2" zellarColour="textInformation">
            {suggestion.region} • {suggestion.appellation}
          </Typography>
        </div>
      </div>
    </MenuItem>
  );
}
