import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GET_SPREAD_PERCENTAGE } from '../../../graphql/queries/spreadValuePercentage';
import { logError } from '../../../utils/logger';
import Typography from '../../Typography';

export default function PercentageDifference({ assetId }: { assetId: number }): JSX.Element {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_SPREAD_PERCENTAGE, {
    variables: { assetId },
  });
  if (loading) {
    return <Typography>...</Typography>;
  }
  if (error) {
    logError({ error, filename: 'PercentageDifference', additionalInfo: { assetId } });
    return <>{t('common:somethingWentWrong')}</>;
  }

  return <Typography customVariant="subtitle1Bold">{data?.omsSpreadByAssetId.percentageDifference}%</Typography>;
}
