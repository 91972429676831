import { Paper, Popper, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  WineVintageSearchSuggestions,
  WineVintageSuggestion,
} from '../../graphql/selectors/selectSearchWineVintagesSuggestions';
import Suggestion from './Suggestion';
import SuggestionGroup from './SuggestionGroup';
import WineSuggestion from './WineSuggestion';

const useStyles = makeStyles<Theme, { parentWidth: number }>((theme) => ({
  root: {
    zIndex: theme.zIndex.modal,
    width: (props) => props.parentWidth,
  },
  innerWrapper: {
    padding: '8px 0',
  },
  inner: {
    maxHeight: '200px',
    overflowY: 'auto',

    '@media (min-height: 700px)': {
      maxHeight: '300px',
    },

    '@media (min-height: 850px)': {
      maxHeight: '400px',
    },
  },
}));

interface SearchSuggestionsProps {
  suggestions: WineVintageSearchSuggestions;
  wineVintageSelected: (wineVintage: WineVintageSuggestion) => void;
  regionSelected: (region: string) => void;
  appellationSelected: (appellation: string) => void;
  producerSelected: (producer: string) => void;
  open: boolean;
  anchorEl: null | HTMLInputElement;
}

export default function SearchSuggestions({
  suggestions,
  anchorEl,
  open,
  wineVintageSelected,
  regionSelected,
  appellationSelected,
  producerSelected,
}: SearchSuggestionsProps): JSX.Element {
  const classes = useStyles({ parentWidth: anchorEl?.clientWidth || 0 });
  const { t } = useTranslation();
  const isOpen = Boolean(anchorEl && open);

  const WineSuggestions = (
    <>
      {suggestions.wines.map((s, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <WineSuggestion onClick={wineVintageSelected} key={`${s.defaultAssetId}-${i}`} suggestion={s} />
      ))}
    </>
  );

  // FIXME: Cannot select options with keyboard controls, need to investigate AutoComplete or implement that support by hand
  // https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/7030
  return (
    <Popper
      className={classes.root}
      anchorEl={anchorEl}
      open={isOpen}
      modifiers={[
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
      ]}
      disablePortal
      keepMounted
    >
      <Paper>
        <div className={classes.innerWrapper}>
          <div className={classes.inner}>
            {suggestions.wines.length > 0 && (
              <SuggestionGroup title={t('common:wine')}>{WineSuggestions}</SuggestionGroup>
            )}
            {suggestions.regions.length > 0 && (
              <SuggestionGroup title={t('common:region')}>
                <>
                  {suggestions.regions.map((region) => (
                    <Suggestion title={t('common:region')} onClick={regionSelected} suggestion={region} key={region} />
                  ))}
                </>
              </SuggestionGroup>
            )}
            {suggestions.appellations.length > 0 && (
              <SuggestionGroup title={t('search:filters.appellation')}>
                <>
                  {suggestions.appellations.map((appellation) => (
                    <Suggestion
                      title={t('search:filters.appellation')}
                      onClick={appellationSelected}
                      suggestion={appellation}
                      key={appellation}
                    />
                  ))}
                </>
              </SuggestionGroup>
            )}
            {suggestions.producers.length > 0 && (
              <SuggestionGroup title={t('search:filters.producer')}>
                <>
                  {suggestions.producers.map((producer) => (
                    <Suggestion
                      title={t('search:filters.producer')}
                      onClick={producerSelected}
                      suggestion={producer}
                      key={producer}
                    />
                  ))}
                </>
              </SuggestionGroup>
            )}
          </div>
        </div>
      </Paper>
    </Popper>
  );
}
