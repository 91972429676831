import { Order } from '../../types';
import { BidForAssetQuery } from '../../__generated__/graphql';

export function selectHighestBid(arg: BidForAssetQuery['productAsset'] | null | undefined): Order | null {
  if (arg && arg.spread && arg.spread.highestBid) {
    return {
      price: arg.spread.highestBid.price ?? null,
      userId: arg.spread.highestBid.userId,
    };
  }

  return null;
}

export function selectUserId(arg: BidForAssetQuery['authUser'] | undefined): string | null {
  return arg?.userId || null;
}
