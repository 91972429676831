import { SelectOption } from '@cultwines/zellar-client-sdk';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '.';
import { useUnitSizes } from '../../hooks/useUnitSizes';
import { selectActiveUnitSize, selectUnitSizeOptions } from './selectors';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '120px',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 700,
    lineHeight: theme.typography.body1.lineHeight,

    [theme.breakpoints.up('sm')]: {
      width: '160px',
    },
  },
}));

interface Props {
  assetId: number;
  afterUnitSizeChange?: (option: SelectOption) => void;
  disableSelect?: boolean;
  onUpdateUnitSize?: (options: SelectOption<string>[]) => SelectOption<string> | undefined | null;
}

export default function UnitSizeSelect({
  assetId,
  afterUnitSizeChange,
  disableSelect,
  onUpdateUnitSize,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const label = t('product:unitSize');
  const { data, error, loading } = useUnitSizes({ assetId });

  const unitSizeOptions = useMemo(() => selectUnitSizeOptions(data?.productAsset), [data]);
  const activeUnitSize = useMemo(() => {
    return onUpdateUnitSize?.(unitSizeOptions) ?? selectActiveUnitSize(data?.productAsset);
  }, [data, onUpdateUnitSize, unitSizeOptions]);

  function onChange(selectedAssetId: number) {
    const newAssetId = unitSizeOptions.find((size) => size.value === selectedAssetId);
    if (newAssetId) {
      afterUnitSizeChange?.(newAssetId);
    }
  }

  if (error) {
    return <Typography>Failed to load data.</Typography>;
  }

  return (
    <Select
      optionId={activeUnitSize?.value}
      loading={loading}
      setOption={onChange}
      options={unitSizeOptions}
      inputLabel={label}
      inputClass={classes.select}
      inlineLabel
      disableSelect={disableSelect}
    />
  );
}
