import { useTheme } from '@mui/material';
import { useQuery, useSubscription } from '@apollo/client';
import Skeleton from '@mui/material/Skeleton';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useMemo } from 'react';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import { Namespace, Resources, TFunction, useTranslation } from 'react-i18next';
import { UNIT_SIZE_QUERY } from '../../graphql/queries/unitSize';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { humanReadableDate } from '../../utils/humanReadableDate';
import BidCard from '../BidCard';
import BuyTradeActionButton from '../BuyTradeActionButton';
import OfferCard from '../OfferCard';
import Typography from '../Typography';
import PercentageDifference from './PercentageDifference';
import QualityGuarantee from './QualityGuarantee';
import { selectUnitSizeData } from './selectors';
import { useStyles } from './styles';
import CreateBuyNowModal from '../BuyNowModal';
import { useQueryParameters } from '../../hooks';
import { Modal } from '../../types/Modal';
import useCanCreateOffer from '../../hooks/useCanCreateOffer';
import OrderModal from '../OrderModal';
import { Mode } from '../OrderModal/types';
import { ORDER_SPREAD_SUBSCRIPTION } from '../../graphql/subscriptions/getOrder';
import { cache } from '../../graphql/cache';
import OrderBookModal from '../OrderBookModal';
import useMediaQuery from '../../hooks/useMediaQuery';
import { logError } from '../../utils/logger';
import { Asset, TradingInfo } from '../../__generated__/graphql';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';

interface ProductTradingSectionProps {
  assetId: number;
}

function selectTradingInfoLabel(
  tradingInfo: TradingInfo | undefined,
  t: TFunction<Namespace<keyof Resources>>,
): string {
  if (!tradingInfo || !tradingInfo.lastTraded || !tradingInfo.lastTradedValue) {
    return t('product:notAvailable');
  }
  return `${humanReadableDate(tradingInfo.lastTraded)} ${formatterWholeNumber.format(tradingInfo.lastTradedValue)}`;
}

export default function ProductTradingSection({ assetId }: ProductTradingSectionProps): JSX.Element {
  const history = useHistory();
  const { desktopOnly } = useFlags();
  const [showOrderBookModal, setShowOrderBookModal] = React.useState(false);
  const theme = useTheme();
  const { showTopUpBuySuccessSummaryModal } = useCardPaymentCallback();
  const greaterThanMd = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md));
  const query = useQueryParameters();
  const modal = query.get('modal');
  const { data: subscriptionData, error: subscriptionError } = useSubscription(ORDER_SPREAD_SUBSCRIPTION, {
    variables: {
      assetId,
    },
  });
  const { canCreateOffer, loading: canCreateOfferQueryLoading } = useCanCreateOffer({ assetId });
  const { data, loading, error } = useQuery(UNIT_SIZE_QUERY, {
    variables: { assetId },
  });

  const { t } = useTranslation();
  const classes = useStyles();

  const unitSizeData = useMemo(() => selectUnitSizeData(data?.productAsset as Asset), [data]);

  useEffect(() => {
    if (subscriptionError) {
      logError({
        error: new Error('Error with ORDER_SPREAD_SUBSCRIPTION'),
        originalError: subscriptionError,
        filename: 'ProductTradingSection',
        additionalInfo: {
          assetId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionError]);

  useEffect(() => {
    if (subscriptionData) {
      cache.evict({ fieldName: 'productAsset' });
      cache.evict({ fieldName: 'omsSpreadByAssetId' });
    }
  }, [subscriptionData]);

  function handleOpenBuyNowModalClicked() {
    history.push(`${history.location.pathname}?modal=${Modal.BuyNow}`);
  }

  function handleOpenBidModalClicked() {
    history.push(`${history.location.pathname}?modal=${Modal.Bid}`);
  }

  function handleOpenOfferModalClicked() {
    history.push(`${history.location.pathname}?modal=${Modal.Offer}`);
  }

  function handleCloseModal() {
    history.push(history.location.pathname);
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const DetailSkeleton = () => (
    <div style={{ width: '33%' }}>
      <Typography variant="subtitle1">
        <Skeleton />
      </Typography>
      <Typography customVariant="subtitle1Bold">
        <Skeleton />
      </Typography>
    </div>
  );
  return (
    <div className={classes.container}>
      <Typography variant="h2">{t('product:trading.title')}</Typography>
      <div className={`${classes.flexRow} ${classes.cards}`}>
        {desktopOnly || greaterThanMd ? (
          <>
            <BidCard className={classes.card} assetId={assetId} />
            <OfferCard className={classes.card} assetId={assetId} />
          </>
        ) : (
          <>
            <BidCard
              className={classes.card}
              assetId={assetId}
              onViewClick={() => setShowOrderBookModal(true)}
              showButton
            />
            <OfferCard
              className={classes.card}
              assetId={assetId}
              onViewClick={() => setShowOrderBookModal(true)}
              showButton
            />
          </>
        )}
      </div>
      <div className={classes.flexRow}>
        {loading ? (
          <>
            <DetailSkeleton />
            <DetailSkeleton />
            <DetailSkeleton />
          </>
        ) : (
          <>
            <div>
              <Typography variant="subtitle1">{t('product:unitSize')}:</Typography>
              <Typography customVariant="subtitle1Bold">{unitSizeData}</Typography>
            </div>
            <div>
              <Typography variant="subtitle1">{t('product:trading.spread')}:</Typography>
              <PercentageDifference assetId={assetId} />
            </div>
            <div>
              <Typography variant="subtitle1">{t('product:trading.lastTrade')}:</Typography>
              <Typography customVariant="subtitle1Bold">
                {selectTradingInfoLabel(data?.productAsset?.tradingInfo as TradingInfo, t)}
              </Typography>
            </div>
          </>
        )}
      </div>
      <BuyTradeActionButton
        onBidClicked={handleOpenBidModalClicked}
        onOfferClicked={handleOpenOfferModalClicked}
        onBuyClicked={handleOpenBuyNowModalClicked}
        assetId={assetId}
      />

      <QualityGuarantee />

      {!isNull(modal) && modal === Modal.Bid && (
        <OrderModal
          assetId={assetId}
          open={modal === Modal.Bid}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="bid"
        />
      )}
      {!isNull(modal) && modal === Modal.Offer && !canCreateOfferQueryLoading && canCreateOffer && (
        <OrderModal
          assetId={assetId}
          open={modal === Modal.Offer}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="offer"
        />
      )}
      {!isNull(modal) && modal === Modal.BuyNow && (
        <CreateBuyNowModal assetId={assetId} open={modal === Modal.BuyNow} onClose={handleCloseModal} />
      )}
      {Boolean(assetId) && showTopUpBuySuccessSummaryModal && (
        <CreateBuyNowModal assetId={Number(assetId)} open onClose={handleCloseModal} />
      )}
      {!desktopOnly && showOrderBookModal && !greaterThanMd && (
        <OrderBookModal assetId={assetId} open={showOrderBookModal} onClose={() => setShowOrderBookModal(false)} />
      )}
    </div>
  );
}
