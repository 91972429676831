import makeStyles from '@mui/styles/makeStyles';
import { onboardingTheme } from '../../theme';

export const useRegistrationFormStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
    textAlign: 'center',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  label: {
    alignSelf: 'flex-start',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
  },
  formErrorAlert: {
    textAlign: 'left',
  },

  selectContainer: {
    width: '100%',
  },

  select: {
    borderRadius: 4,
    textAlign: 'left',
    position: 'relative',
    width: '100% !important',
    height: '1.4375em !important',
    paddingLeft: '16.5px',
    paddingRight: '16.5px',
    paddingTop: '14px',
    paddingBottom: '14px',
    backgroundColor: onboardingTheme.palette.dividers.primary,
    border: 0,
    '&:focus': {
      borderRadius: 4,
    },
    color: onboardingTheme.palette.textField.text,
    '&:hover fieldset': {
      borderColor: `${onboardingTheme.palette.dividers.primary} !important`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${onboardingTheme.palette.dividers.primary} !important`,
    },
  },
  consentContainer: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.dividers.primary}`,
    borderRadius: 4,
  },
}));
