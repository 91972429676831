import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { EXTERNAL_PORTFOLIO_INSTANCE_QUERY } from '../../../graphql/queries/externalPortfolioInstance';
import Divider from '../../Divider';
import AssetInstance, { AssetInstanceProps as InstanceProps } from './ExternalItemAssetInstance';

const useStyles = makeStyles((theme) => ({
  instanceSkeleton: {
    width: `calc(100% - ${theme.spacing(2.5)})`,
    height: '80px',
    margin: theme.spacing(2.5),
  },
  divider: {
    marginLeft: -24,
    marginRight: -24,
  },
  dividerContainer: {
    width: '100%',
  },
}));

interface AssetInstancesProps {
  assetId: number;
  AssetInstanceProps?: Partial<InstanceProps>;
}

export default function AssetInstances({ assetId, AssetInstanceProps }: AssetInstancesProps): JSX.Element {
  const classes = useStyles();
  const { loading, data, error } = useQuery(EXTERNAL_PORTFOLIO_INSTANCE_QUERY, {
    variables: { assetId },
    pollInterval: 3000,
  });

  if (error) {
    return <div>{error.message}</div>;
  }

  if (loading) {
    return (
      <>
        {new Array(6).fill(0).map((_, i) => (
          <Skeleton
            className={classes.instanceSkeleton}
            variant="rectangular"
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {data?.externalPortfolioUserAssetInstances.map((instance, i) => (
        <>
          <AssetInstance
            assetId={assetId}
            key={`${assetId}-${instance.id}`}
            instance={instance}
            className={AssetInstanceProps ? AssetInstanceProps.className : ''}
          />
          {i < data.externalPortfolioUserAssetInstances.length && (
            <div className={classes.dividerContainer}>
              <Divider className={classes.divider} variant="fullWidth" customVariant="tertiary" />
            </div>
          )}
        </>
      ))}
    </>
  );
}
