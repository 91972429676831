import makeStyles from '@mui/styles/makeStyles';

export const useFooterStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: '0 20px',
  },

  footerLinks: {
    display: 'flex',
    gap: theme.spacing(2),
  },

  link: {
    ...theme.typography.body1,
    color: 'white',
    textDecorationColor: 'white',
  },
}));
