import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import TextField from '../TextField';
import Typography from '../Typography';
import { useStyles } from './styles';
import { FormState } from './types';

interface Props {
  onClose: () => void;
  onSuccess: (formState: FormState) => void;
  formState: FormState;
}

export default function FormContent({ onClose, onSuccess, formState }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const validationSchema = yup.object({
    cardHolderName: yup.string().required(t('wallet:addPaymentOptionModal.cardholderName.required')),
    cardNumber: yup
      .string()
      .required(t('wallet:addPaymentOptionModal.cardNumber.required'))
      .test('val', t('wallet:addPaymentOptionModal.cardNumber.length'), (val) => !val?.includes('_')),
    expiry: yup
      .string()
      .required(t('wallet:addPaymentOptionModal.expiry.required'))
      .test('val', t('wallet:addPaymentOptionModal.expiry.required'), (val) => !val?.includes('_'))
      .test('val', t('wallet:addPaymentOptionModal.expiry.invalid'), (val) => {
        if (!val) {
          return false;
        }
        const [month, year] = val?.split('/');
        if (Number(month) > 12 || Number(month) < 1) {
          return false;
        }
        if (Number(`20${year}`) < new Date().getFullYear()) {
          return false;
        }
        return true;
      }),
    cvv: yup
      .string()
      .required(t('wallet:addPaymentOptionModal.cvv.required'))
      .matches(/^[0-9]+$/, t('common:validation.digitsOnly'))
      .min(3, t('wallet:addPaymentOptionModal.cvv.length')),
    makeAsDefault: yup.boolean().required(),
  });

  const formik = useFormik<yup.InferType<typeof validationSchema>>({
    initialValues: formState,
    onSubmit: async (formData) => onSuccess(formData),
    validationSchema,
  });

  return (
    <div className={classes.modalContainer}>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <DialogTitle id="dialog-title" onClose={onClose}>
          {t('wallet:addPaymentOptionModal.title')}
        </DialogTitle>
        <DialogContentBorderTopOnly dividers>
          <div className={classes.container}>
            <div className={classes.halfWidth}>
              <Typography className={classes.inputLabel} variant="subtitle2">
                {t('wallet:addPaymentOptionModal.cardholderName.label')}
              </Typography>
              <TextField
                id="cardHolderName"
                name="cardHolderName"
                className={classes.textField}
                value={formik.values.cardHolderName}
                placeholder={t('wallet:addPaymentOptionModal.cardholderName.label')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.cardHolderName && Boolean(formik.errors.cardHolderName)}
                helperText={formik.touched.cardHolderName && formik.errors.cardHolderName}
                variant="outlined"
              />
            </div>
            <div className={classes.halfWidth}>
              <Typography className={classes.inputLabel} variant="subtitle2">
                {t('wallet:addPaymentOptionModal.cardNumber.label')}
              </Typography>
              <InputMask
                mask="9999 9999 9999 9999"
                value={formik.values.cardNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {() => (
                  <TextField
                    className={classes.textField}
                    name="cardNumber"
                    placeholder={t('wallet:addPaymentOptionModal.cardNumber.placeholder')}
                    error={formik.touched.cardNumber && Boolean(formik.errors.cardNumber)}
                    helperText={formik.touched.cardNumber && formik.errors.cardNumber}
                    variant="outlined"
                  />
                )}
              </InputMask>
            </div>
            <div className={classes.halfWidth}>
              <Typography className={classes.inputLabel} variant="subtitle2">
                {t('wallet:addPaymentOptionModal.expiry.label')}
              </Typography>
              <InputMask
                mask="99/99"
                value={formik.values.expiry}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {() => (
                  <TextField
                    name="expiry"
                    className={classes.textField}
                    error={formik.touched.expiry && Boolean(formik.errors.expiry)}
                    helperText={formik.touched.expiry && formik.errors.expiry}
                    placeholder={t('wallet:addPaymentOptionModal.expiry.placeholder')}
                    variant="outlined"
                  />
                )}
              </InputMask>
            </div>
            <div className={classes.halfWidth}>
              <Typography className={classes.inputLabel} variant="subtitle2">
                {t('wallet:addPaymentOptionModal.cvv.label')}
              </Typography>
              <TextField
                id="cvv"
                name="cvv"
                className={classes.textField}
                value={formik.values.cvv}
                placeholder={t('wallet:addPaymentOptionModal.cvv.placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.cvv && Boolean(formik.errors.cvv)}
                helperText={formik.touched.cvv && formik.errors.cvv}
                variant="outlined"
                inputProps={{ maxLength: 3 }}
              />
            </div>
            <FormControlLabel
              className={classes.checkboxContainer}
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={formik.values.makeAsDefault}
                  onChange={formik.handleChange}
                  name="makeAsDefault"
                  color="primary"
                />
              }
              label={t('wallet:paymentOptions.makeDefaultCard')}
            />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              onClick={() => onSuccess(formState)}
            >
              {t('wallet:paymentOptions.addCard')}
            </Button>
          </div>
        </DialogContentBorderTopOnly>
      </form>
    </div>
  );
}
