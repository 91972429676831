import React from 'react';
import { ExternalPortfolioItem } from '../../graphql/selectors/externalPortfolioItems';
import selectDrinkingWindowAdvice from '../../graphql/selectors/selectDrinkingWindowAdvice';
import { formatter } from '../../utils/currencyFormatter';
import { isNull } from '../../utils/isNull';
import {
  BasicCell,
  CustomCell,
  DrinkingWindowCell,
  Row as RowType,
  ScoreCell,
  SparklineCell,
  UnitSizeCell,
  WineNameCell,
} from '../Table/types';
import TradingTile from '../TradingTile';
import isUndefined from '../../utils/isUndefined';

export interface TableData extends RowType {
  name: WineNameCell;
  vintage: BasicCell;
  drinkingWindow: DrinkingWindowCell;
  units: BasicCell;
  unitSize: UnitSizeCell;
  score: ScoreCell;
  totalPurchasePrice: BasicCell;
  totalMarketValue: BasicCell;
  performance: SparklineCell;
  highestBid: CustomCell;
  lowestOffer: CustomCell;
}

interface SelectTableDataArgs {
  externalPortfolioItems: ExternalPortfolioItem[];
  handleOpenBidModalClicked: (rowId: number) => void;
  handleOpenBuyNowModalClicked: (rowId: number) => void;
  clickableTradeTiles13816?: boolean;
}

export function selectTableData({
  externalPortfolioItems,
  handleOpenBidModalClicked,
  handleOpenBuyNowModalClicked,
  clickableTradeTiles13816,
}: SelectTableDataArgs): TableData[] {
  return externalPortfolioItems.map((item) => {
    const HighestBidTradingTile = clickableTradeTiles13816 ? (
      <TradingTile mode="bid" value={item.highestBid} onClick={() => handleOpenBidModalClicked(item.id)} />
    ) : (
      <TradingTile mode="bid" value={item.highestBid} unitCount={item.unitCount} unitSize={item.unitSize} />
    );

    const LowestOfferTradingTile = clickableTradeTiles13816 ? (
      <TradingTile
        mode="offer"
        value={item.lowestOffer}
        onClick={!isUndefined(item.lowestOffer) ? () => handleOpenBuyNowModalClicked(item.id) : undefined}
      />
    ) : (
      <TradingTile mode="offer" value={item.lowestOffer} unitCount={item.unitCount} unitSize={item.unitSize} />
    );

    return {
      name: {
        appellation: null,
        region: null,
        wineName: item.name,
        link: `/product/${item.id}`,
      },
      vintage: {
        mainContent: item.vintage,
      },
      drinkingWindow: {
        advice: selectDrinkingWindowAdvice(item.drinkingWindow?.advice),
        date: !isNull(item.drinkingWindow) && item.drinkingWindow.dateFrom ? item.drinkingWindow.dateFrom : undefined,
      },
      units: {
        mainContent: item.units,
      },
      unitSize: {
        unitCount: item.unitCount,
        unitSize: item.unitSize,
      },
      score: {
        score: item.score,
      },
      totalPurchasePrice: {
        mainContent: formatter.format(item.totalPurchasePrice),
      },
      totalMarketValue: {
        mainContent: formatter.format(item.totalMarketValue),
      },
      performance: {
        // TODO: we do not have the value for the following available in the GraphQL query schema as of yet
        data: {},
        deltaNum: item.performanceValueDelta,
        deltaPct: item.performancePercentageDelta,
      },
      highestBid: {
        content: HighestBidTradingTile,
      },
      lowestOffer: {
        content: LowestOfferTradingTile,
      },
      rowId: item.id.toString(),
    };
  });
}
