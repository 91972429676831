/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import LoginForm from '../../components/LoginForm';
import OTPForm from '../../components/OTPForm';
import Splash from '../../components/Splash';
import { useStyles } from './styles';
import Footer from '../../components/Footer';

export default function Login(): JSX.Element {
  const classes = useStyles();
  const [mfaOOBCode, setMFAOOBCode] = useState<string | null>(null);
  const [mfaToken, setMFAToken] = useState<string | null>(null);

  function handleMFARequested({
    mfaOOBCode: _mfaOOBCode,
    mfaToken: _mfaToken,
  }: {
    mfaToken: string;
    mfaOOBCode: string;
    emailAddress: string;
  }): void {
    setMFAOOBCode(_mfaOOBCode);
    setMFAToken(_mfaToken);
  }

  return (
    <Splash>
      <div className={classes.wrapper}>
        <Box className={classes.formBox}>
          {mfaToken && mfaOOBCode ? (
            <OTPForm mfaToken={mfaToken} mfaOOBCode={mfaOOBCode} />
          ) : (
            <LoginForm onMFARequested={handleMFARequested} />
          )}
        </Box>
        <Footer />
      </div>
    </Splash>
  );
}
