import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useFooterStyles } from './styles';

export default function Footer(): JSX.Element {
  const classes = useFooterStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.footer}>
      <Typography color="textSecondary" variant="subtitle1">
        {t('common:appName')}
      </Typography>
      <Typography color="textSecondary" variant="subtitle1">
        {t('common:businessAddress')}
      </Typography>
      <div className={classes.footerLinks}>
        <Link underline="always" color="textPrimary" variant="body1" component={RouterLink} to="/register">
          {t('auth:loginForm.registerLink')}
        </Link>
        <Typography variant="body1">|</Typography>
        <a href="https://www.cultx.com/privacy" className={classes.link} target="_blank" rel="noreferrer">
          {t('auth:termsAndConditionsLink')}
        </a>
      </div>
    </div>
  );
}
