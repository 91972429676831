/* eslint-disable no-nested-ternary */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import { GraphQLError } from 'graphql';
import { useFlags } from 'launchdarkly-react-client-sdk';
import TableCellHead from '../../../../components/TableElements/TableCellHead';
import RowSkeleton from '../../../../components/TableElements/RowSkeleton';
import ErrorPlaceholder from '../../../../components/ErrorPlaceholder';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import WineNameCell from '../../../../components/Table/Cells/WineName';
import BasicCell from '../../../../components/Table/Cells/Basic';
import UnitSizeCell from '../../../../components/Table/Cells/UnitSize';
import { formatterWholeNumber } from '../../../../utils/currencyFormatter';
import TradingTile from '../../../../components/TradingTile';
import ActionsCell from '../../../../components/TableElements/ActionsCell';
import { ActiveMarket } from '../../../../__generated__/graphql';
import WatchlistButton from '../../../../components/WatchlistButton';
import BuyTradeActionButton, { Size as BuyTradeButtonSize } from '../../../../components/BuyTradeActionButton';
import { isNullOrUndefined } from '../../../../utils/isNullOrUndefined';

interface TableProps {
  assets: ActiveMarket[];
  loading?: boolean;
  error?: string;
  onAddToWatchlist: () => void;
  onRemoveFromWatchlist: () => void;
  onWatchlistError: (errors: readonly GraphQLError[]) => void;
  onBuyNowClicked: (assetId: number) => void;
  onBidClicked: (assetId: number) => void;
  onOfferClicked: (assetId: number) => void;
}

export default function ActiveMarketsTable({
  assets,
  loading,
  error,
  onAddToWatchlist,
  onRemoveFromWatchlist,
  onWatchlistError,
  onBuyNowClicked,
  onBidClicked,
  onOfferClicked,
}: TableProps) {
  const { t } = useTranslation();
  const { clickableTradeTiles13816 } = useFlags();
  const columnCount = 9;

  return (
    <TableContainer>
      <Table aria-label="active markets table">
        <TableHead>
          <TableRow>
            <TableCellHead title={t('market:table.wineName')} hideSortButton />
            <TableCellHead title={t('common:region')} hideSortButton />
            <TableCellHead title={t('common:vintage')} hideSortButton />
            <TableCellHead title={t('common:unitSize')} hideSortButton />
            <TableCellHead
              title={t('product:marketValue')}
              tooltip={t('market:marketValueCalculation')}
              hideSortButton
            />
            <TableCellHead title={t('product:trading.highestBid')} hideSortButton />
            <TableCellHead title={t('product:trading.lowestOffer')} hideSortButton />
            <TableCellHead title={t('product:trading.spread')} hideSortButton />
            <TableCellHead title={t('common:actions')} hideSortButton />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <RowSkeleton columnCount={columnCount} />
          ) : error ? (
            <TableCell align="center" colSpan={columnCount}>
              <ErrorPlaceholder error={error} />
            </TableCell>
          ) : !assets.length ? (
            <TableCell align="center" colSpan={columnCount}>
              <EmptyPlaceholder label={t('discover:activeMarkets.noResultsNoFilter')} />
            </TableCell>
          ) : (
            assets.map((asset) => (
              <TableRow key={asset.assetId}>
                <WineNameCell
                  wineName={asset.wineName}
                  appellation={null}
                  link={`/product/${asset.assetId}`}
                  region={asset.region}
                />
                <BasicCell mainContent={asset.region} />
                <BasicCell mainContent={asset.vintage} />
                <UnitSizeCell unitCount={asset.unitCount} unitSize={asset.unitSize} />
                <BasicCell mainContent={formatterWholeNumber.format(asset.marketValue)} />
                <TableCell>
                  {clickableTradeTiles13816 ? (
                    <TradingTile
                      mode="bid"
                      value={asset.highestBid.price}
                      onClick={() => onBidClicked(asset.assetId)}
                    />
                  ) : (
                    <TradingTile
                      mode="bid"
                      value={asset.highestBid.price}
                      createdDate={
                        isNullOrUndefined(asset.highestBid.createdDate)
                          ? ''
                          : new Date(asset.highestBid.createdDate).toLocaleDateString()
                      }
                    />
                  )}
                </TableCell>
                <TableCell>
                  {clickableTradeTiles13816 ? (
                    <TradingTile
                      mode="offer"
                      value={asset.lowestOffer.price}
                      onClick={!isUndefined(asset.lowestOffer.price) ? () => onBuyNowClicked(asset.assetId) : undefined}
                    />
                  ) : (
                    <TradingTile
                      mode="offer"
                      value={asset.lowestOffer.price ?? 0}
                      createdDate={
                        isNullOrUndefined(asset.lowestOffer.createdDate)
                          ? ''
                          : new Date(asset.lowestOffer.createdDate).toLocaleDateString()
                      }
                    />
                  )}
                </TableCell>
                <BasicCell mainContent={isNull(asset.spread) ? '-' : `${asset.spread}%`} />
                <ActionsCell>
                  <WatchlistButton
                    assetId={asset.assetId}
                    onError={onWatchlistError}
                    onAdd={onAddToWatchlist}
                    onRemove={onRemoveFromWatchlist}
                  />
                  <BuyTradeActionButton
                    assetId={asset.assetId}
                    onBidClicked={() => onBidClicked(asset.assetId)}
                    onOfferClicked={() => onOfferClicked(asset.assetId)}
                    onBuyClicked={() => onBuyNowClicked(asset.assetId)}
                    size={BuyTradeButtonSize.Small}
                    condensed
                  />
                </ActionsCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
