import { isUndefined } from '@cultwines/zellar-client-sdk';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import MuiToolbar from '@mui/material/Toolbar';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as ListIcon } from '../../assets/icons/list.svg';
import { ReactComponent as CardIcon } from '../../assets/icons/card-view.svg';
import { ReactComponent as MultiSelectIcon } from '../../assets/icons/multi-select.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as SortIcon } from '../../assets/icons/sort.svg';
import { ReactComponent as TransferHeavyCar } from '../../assets/icons/transfer-heavy-car.svg';
import { useSelectedRowsContext } from '../../context/selectedRowsContext';
import IconButton from '../IconButton';
import ToggleButton, { ToggleButtonProps } from '../ToggleButton';
import Typography from '../Typography';
import SortMenu, { SortOption } from './SortMenu';
import { SortDirection } from './types';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useShouldRenderMarketRoute } from '../../hooks/useShouldRenderMarketRoute';
import TextField from '../TextField';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  toolbar: {
    minHeight: '50px',
    display: 'flex',
  },
  right: {
    marginLeft: 'auto',
  },
  resultsCount: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: theme.spacing(3),
  },
  controls: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  icon: {
    stroke: theme.palette.textLabel,
  },
  fill: {
    fill: theme.palette.textLabel,
  },
  badge: {
    background: theme.palette.success.main,
    color: theme.palette.textPrimary,
  },
  compareSection: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  searchTextField: {
    marginLeft: 6,
    width: 400,
  },
  searchTextFieldInput: {
    borderRadius: '35px',
    height: 38,
  },
  searchTextFieldInputInner: {
    padding: 10,
  },
  mobileSearch: {
    width: '100%',
    marginBottom: 12,
  },
  circledCrossIcon: {
    padding: 0,
    stroke: theme.palette.grey[200],
  },
}));

export type ViewType = 'card' | 'table';
interface ToolbarProps<K> {
  resultCount?: number;
  viewType?: ViewType;
  onChangeViewType?: (viewType: ViewType) => void;
  onSortChanged?: (id: string, sortDirection: SortDirection) => void;
  openFiltersClicked?: () => void;
  activeFiltersCount?: number;
  sortOptions?: SortOption<K>[];
  hideChangeViewType?: boolean;
  onCompareClicked?: () => void;
  displayCompareButton?: boolean;
  displayTransferOutsideCultwineButton?: boolean;
  onTransferOutsideCultwineClicked?: () => void;
  addWineToExternalPortfolio?: boolean;
  addWineToExternalPortfolioClicked?: () => void;
  toggleButtonProps?: ToggleButtonProps;
  searchText?: string;
  onSearchTextChanged?: (text: string) => void;
  searchFieldPlaceholder?: string;
  preferSortMenuButton?: boolean;
}

/**
 * ⚠️ This component relies on the `SelectedRowsProvider` being placed higher up in the component tree.
 */
export default function Toolbar<K>({
  resultCount,
  onChangeViewType,
  viewType,
  hideChangeViewType = false,
  sortOptions,
  activeFiltersCount = 0,
  onSortChanged,
  openFiltersClicked,
  displayCompareButton = false,
  displayTransferOutsideCultwineButton = false,
  onCompareClicked,
  onTransferOutsideCultwineClicked,
  addWineToExternalPortfolio = false,
  addWineToExternalPortfolioClicked,
  toggleButtonProps,
  searchText,
  onSearchTextChanged,
  searchFieldPlaceholder,
  preferSortMenuButton = false,
}: ToolbarProps<K>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [sortMenuAnchor, setSortMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [mobileSearchActive, setMobileSearchActive] = React.useState(false);
  const sortMenuOpen = Boolean(sortMenuAnchor);
  const { state, dispatch } = useSelectedRowsContext();
  const { transferAssetsOutOfCultX } = useFlags();
  const { shouldRenderMarketRoute } = useShouldRenderMarketRoute();

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>): void {
    setSortMenuAnchor(event.currentTarget);
  }

  function handleMenuClose(id: string, sortDirection: SortDirection): void {
    setSortMenuAnchor(null);

    if (onSortChanged) {
      onSortChanged(id, sortDirection);
    }
  }

  const handleSearchTextChanged: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    onSearchTextChanged?.(event.target.value);
  };

  function handleMobileSearchClosed(): void {
    onSearchTextChanged?.('');
    setMobileSearchActive(false);
  }

  React.useEffect(() => {
    if (viewType === 'card') {
      setSortMenuAnchor(null);
    }
  }, [viewType]);
  const displayTransferIcon =
    displayTransferOutsideCultwineButton &&
    onTransferOutsideCultwineClicked &&
    !state.selectModeActive &&
    !state.selectTransferModeActive;

  const displayMultiselect =
    displayCompareButton &&
    onCompareClicked &&
    shouldRenderMarketRoute &&
    !state.selectModeActive &&
    !state.selectTransferModeActive;

  const displayOnCompareSection =
    displayCompareButton && onCompareClicked && state.selectModeActive && shouldRenderMarketRoute;

  const displayTransferOutsideCultwineSection =
    displayTransferOutsideCultwineButton && onTransferOutsideCultwineClicked && state.selectTransferModeActive;

  const displaySortMenuButton = sortOptions && onSortChanged && (viewType === 'card' || preferSortMenuButton);

  return (
    <MuiToolbar>
      <div className={classes.wrapper}>
        <div className={classes.toolbar}>
          <div className={classes.resultsCount}>
            {toggleButtonProps && (
              <ToggleButton
                onToggle={toggleButtonProps.onToggle}
                falseStateName={toggleButtonProps.falseStateName}
                trueStateName={toggleButtonProps.trueStateName}
                toggleState={toggleButtonProps.toggleState}
              />
            )}
            {!isUndefined(resultCount) && (
              <Typography customVariant="subtitle3" zellarColour="textLabel">
                {resultCount} {resultCount === 1 ? t('common:result') : t('common:results')}
              </Typography>
            )}
          </div>
          <div className={`${classes.right} ${classes.controls}`}>
            {!isUndefined(searchText) && !isUndefined(onSearchTextChanged) && !lessThanMd && (
              <TextField
                variant="outlined"
                value={searchText}
                onChange={handleSearchTextChanged}
                placeholder={searchFieldPlaceholder}
                className={classes.searchTextField}
                InputProps={{ className: classes.searchTextFieldInput, startAdornment: <Search htmlColor="#B8B8B8" /> }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ className: classes.searchTextFieldInputInner, 'aria-label': 'text-filter' }}
              />
            )}
            {!isUndefined(searchText) && !isUndefined(onSearchTextChanged) && lessThanMd && !mobileSearchActive && (
              <IconButton onClick={() => setMobileSearchActive(true)} aria-label="search-button" size="large">
                <Search />
              </IconButton>
            )}
            {!isUndefined(searchText) && !isUndefined(onSearchTextChanged) && lessThanMd && mobileSearchActive && (
              <IconButton onClick={handleMobileSearchClosed} size="large">
                <Clear />
              </IconButton>
            )}

            {displayTransferIcon && transferAssetsOutOfCultX && (
              <IconButton onClick={() => dispatch({ type: 'selectTransferModeEnabled' })} size="large">
                <TransferHeavyCar data-testid="tranfer-car-icon" className={classes.icon} />
              </IconButton>
            )}
            {displayMultiselect && (
              <IconButton onClick={() => dispatch({ type: 'selectModeEnabled' })} size="large">
                <MultiSelectIcon data-testid="compare-icon" className={classes.icon} />
              </IconButton>
            )}
            {displayOnCompareSection && (
              <div className={classes.compareSection}>
                <Typography customVariant="subtitle2">{`${state.selectedRows.length}/${state.maxRows} ${t(
                  'common:table.toolbar.winesSelected',
                )}`}</Typography>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => dispatch({ type: 'selectModeDisabled' })}
                  size={lessThanMd ? 'small' : 'medium'}
                >
                  {t('common:cancel')}
                </Button>
                <Button
                  variant="contained"
                  onClick={onCompareClicked}
                  color="primary"
                  disabled={state.selectedRows.length === 0}
                  size={lessThanMd ? 'small' : 'medium'}
                >
                  {t('common:compare')}
                </Button>
              </div>
            )}
            {displayTransferOutsideCultwineSection && (
              <div className={classes.compareSection}>
                <Typography customVariant="subtitle2">{`${
                  state.selectedTransferedRows.filter((str) => str.type === 'AssetInstance').length
                } ${t('common:table.toolbar.winesSelected')}`}</Typography>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => dispatch({ type: 'selectModeDisabled' })}
                  size={lessThanMd ? 'small' : 'medium'}
                >
                  {t('common:cancel')}
                </Button>
                <Button
                  variant="contained"
                  size={lessThanMd ? 'small' : 'medium'}
                  onClick={onTransferOutsideCultwineClicked}
                  color="primary"
                  disabled={state.selectedTransferedRows.length === 0}
                >
                  {lessThanMd ? t('common:transfer') : t('common:requestTransfer')}
                </Button>
              </div>
            )}
            {openFiltersClicked && (
              <IconButton onClick={openFiltersClicked} size="large">
                <Badge classes={{ badge: classes.badge }} badgeContent={activeFiltersCount}>
                  <FilterIcon className={classes.icon} />
                </Badge>
              </IconButton>
            )}
            {displaySortMenuButton && (
              <IconButton onClick={handleMenuOpen} size="large">
                <SortIcon className={classes.icon} />
              </IconButton>
            )}
            {viewType === 'card' && onChangeViewType && !hideChangeViewType && (
              <IconButton onClick={() => onChangeViewType('table')} size="large">
                <ListIcon className={classes.icon} />
              </IconButton>
            )}
            {viewType === 'table' && onChangeViewType && !hideChangeViewType && (
              <IconButton onClick={() => onChangeViewType('card')} size="large">
                <CardIcon />
              </IconButton>
            )}
            {addWineToExternalPortfolio && (
              <IconButton onClick={addWineToExternalPortfolioClicked} size="large">
                <PlusIcon className={classes.icon} />
              </IconButton>
            )}
          </div>
        </div>
        {lessThanMd && mobileSearchActive && !isUndefined(searchText) && !isUndefined(onSearchTextChanged) && (
          <div className={classes.mobileSearch}>
            <TextField
              variant="outlined"
              value={searchText}
              onChange={handleSearchTextChanged}
              placeholder={searchFieldPlaceholder}
              className={classes.searchTextField}
              InputProps={{
                className: classes.searchTextFieldInput,
                startAdornment: <Search htmlColor="#B8B8B8" />,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ className: classes.searchTextFieldInputInner, 'aria-label': 'text-filter' }}
            />
          </div>
        )}
      </div>
      <SortMenu
        options={sortOptions ?? []}
        anchorEl={sortMenuAnchor}
        open={sortMenuOpen}
        optionSelected={handleMenuClose}
        onClose={() => setSortMenuAnchor(null)}
        /**
         * It's very awkward to simply place this popover menu below the button which opens it,
         * had to apply the following three props in this way to make it work. See
         * https://medium.com/cloud-native-the-gathering/material-ui-how-to-change-a-menus-menuitems-anchor-to-popover-below-instead-of-over-ab222e175cfd
         * for more info. Material UI by design has the popover appear over (z) the element you clicked to open it,
         * instead of below (y), which is what we want to do.
         */
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </MuiToolbar>
  );
}
