import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import Typography from '../../Typography';
import { DrinkingWindowCell as DrinkingWindowCellProps } from '../types';
import DrinkingWindowChip from '../../DrinkingWindowChip';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
  },
}));

export default function DrinkingWindowCell({ advice, date }: DrinkingWindowCellProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <TableCell>
      <div className={classes.container}>
        <DrinkingWindowChip advice={advice} />
        {date && advice !== 'unknown' && (
          <Typography customVariant="subtitle2">{`${t('common:drinkingWindow.date')} ${date}`}</Typography>
        )}
      </div>
    </TableCell>
  );
}
