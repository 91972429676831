import React from 'react';
import { MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '../Typography';
import { ReactComponent as WineBottles } from '../../assets/icons/wine-bottles.svg';
import { WineVintageSuggestion } from '../../graphql/selectors/selectSearchWineVintagesSuggestions';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  iconContainer: {
    padding: 6,
    background: theme.palette.grey[50],
    borderRadius: 4,
  },
  icon: {
    width: '2rem',
    height: '2rem',
    stroke: theme.palette.textPrimary,
  },
  text: { whiteSpace: 'break-spaces' },
}));

export type RecentSearch =
  | {
      id: string;
      value: string;
      url: string;
      variant: 'region' | 'search' | 'appellation' | 'producer';
    }
  | {
      id: string;
      value: string;
      url: string;
      variant: 'wine';
      suggestion: WineVintageSuggestion;
    };
interface RecentSearchProps {
  item: RecentSearch;
  onClick: (recentSearch: RecentSearch) => void;
}

export default function RecentSearchComponent({ item, onClick }: RecentSearchProps): JSX.Element {
  const classes = useStyles();
  return (
    <MenuItem className={classes.container} onClick={() => onClick(item)}>
      <div className={classes.iconContainer}>
        <WineBottles className={classes.icon} />
      </div>
      <div>
        <Typography className={classes.text} customVariant="subtitle1">
          {item.value}
        </Typography>
      </div>
    </MenuItem>
  );
}
