import { ApolloError, useQuery } from '@apollo/client';
import { AVAILABLE_UNIT_SIZES } from '../graphql/queries/unitSize';
import { UnitSizesByAssetIdQuery } from '../__generated__/graphql';

interface UseUnitSizesReturnType {
  // TODO: Eventually this should be changed so it returns something
  // slightly more sensible. Trying to contain scope right now.
  data: UnitSizesByAssetIdQuery | undefined;
  error: ApolloError | undefined;
  loading: boolean;
}

export function useUnitSizes({ assetId }: { assetId: number }): UseUnitSizesReturnType {
  const { data, loading, error } = useQuery(AVAILABLE_UNIT_SIZES, {
    variables: {
      assetId,
    },
  });

  return { data, loading, error };
}
