import { ApolloError, useQuery } from '@apollo/client';
import { GET_WINE_SCORES } from '../graphql';
import { GetWineScoresQueryVariables, WineScore } from '../__generated__/graphql';

interface UseWalletBalanceHookResponse {
  wineScores: WineScore[];
  error: ApolloError | undefined;
  loading: boolean;
}

export const useWineScores = ({ assetId }: GetWineScoresQueryVariables): UseWalletBalanceHookResponse => {
  const { data, loading, error } = useQuery(GET_WINE_SCORES, {
    variables: {
      assetId,
    },
  });
  const wineScores = data?.productAsset?.vintage.wineScores ?? [];
  return { wineScores, loading, error };
};
