import Dialog from '@mui/material/Dialog';
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { billingCountrySelectOptions } from '@cultwines/zellar-client-sdk';
import AddressModal from './addressModal';
import IdentificationModal from './identificationModal';
import { IdentificationFormModel } from './types';
import ModalContentSwitcher, { ModalStep } from '../Dialog/ContentSwitcher';
import KycFlowSuccessContent from './KycFlowSuccessContent';
import KycFlowFailureContent from './KycFlowFailureContent';

const useStyles = makeStyles(() => ({
  container: {
    padding: 15,
    minHeight: 286,
    maxHeight: 680,
    minWidth: 575,
    maxWidth: 575,
    width: '100%',
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function KycFlowModal({ open, onClose }: Props): JSX.Element {
  const classes = useStyles();
  const country = billingCountrySelectOptions.find((c) => c.label === 'United Kingdom')!;

  const initModel = {
    dob: '',
    firstName: '',
    lastName: '',
    middleName: '',
  };
  const [model, setModel] = useState<IdentificationFormModel>(initModel);
  const [currentModalStep, setCurrentModalStep] = useState(ModalStep.Initial);
  function onCloseWithModalStateReset() {
    onClose();
    setTimeout(() => {
      setModel(initModel);
      setCurrentModalStep(ModalStep.Initial);
    }, 500);
  }
  function onCloseWithoutModalStateReset() {
    onClose();
    setTimeout(() => {
      setCurrentModalStep(ModalStep.Initial);
    }, 500);
  }

  function selectModalStyles(step: ModalStep, lowerThanMd: boolean) {
    if (step === ModalStep.Initial) {
      return { minHeight: 460, minWidth: lowerThanMd ? 550 : 768 };
    }
    if (step === ModalStep.Summary) {
      return { minHeight: 340, minWidth: lowerThanMd ? 500 : 720 };
    }
    return { minHeight: 410, minWidth: lowerThanMd ? 380 : 600 };
  }
  return (
    <Dialog onClose={onCloseWithModalStateReset} open={open} disableEscapeKeyDown maxWidth="lg">
      <ModalContentSwitcher
        modalStep={currentModalStep}
        selectStyles={() => selectModalStyles(currentModalStep, true)}
        InitialComponent={() => (
          <div className={classes.container}>
            <IdentificationModal
              onClose={onCloseWithModalStateReset}
              formModel={{ ...model }}
              onNext={(value) => {
                setModel(value);
                setCurrentModalStep(ModalStep.Summary);
              }}
            />
          </div>
        )}
        SummaryComponent={() => (
          <AddressModal
            initialValues={{
              firstName: model.firstName,
              middleName: model.middleName,
              lastName: model.lastName,
              dob: model.dob,
              addressLine1: '',
              addressLine2: '',
              addressLine3: '',
              county: '',
              town: '',
              postcode: '',
              country,
            }}
            onClose={onCloseWithModalStateReset}
            onSuccess={() => setCurrentModalStep(ModalStep.Success)}
            onFailure={() => setCurrentModalStep(ModalStep.Failure)}
          />
        )}
        SuccessComponent={() => <KycFlowSuccessContent onClose={onCloseWithoutModalStateReset} />}
        FailureComponent={() => <KycFlowFailureContent onClose={onCloseWithoutModalStateReset} />}
      />
    </Dialog>
  );
}
