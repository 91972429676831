import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RRLink } from 'react-router-dom';
import * as SearchChipsGroupData from '@cultwines/zellar-client-sdk/data/suggestedSearchGroups';
import { generateAppendedQueryString } from '@cultwines/zellar-client-sdk/utils/generateAppendedQueryString';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import Typography from '../Typography';
import { colours } from '../../theme/light/palette';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  // TODO: Come back and define theme-wide chip styles
  // https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/6625
  chip: {
    background: 'white',
    border: `1px solid ${theme.palette.textLabel}`,
    color: theme.palette.textLabel,

    '&:hover': {
      background: theme.palette.textPrimary,
      color: theme.palette.textSecondary,
      cursor: 'pointer',
    },
  },
  enPrimeurChip: {
    background: colours.brightOrange,
    border: `1px solid ${colours.brightOrange}`,
    color: 'white',

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function SuggestedSearches({ className = '' }: { className?: string }): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={`${classes.container} ${className}`}>
      <Typography customVariant="h4">{t('discover:suggestedSearches.title')}</Typography>
      <div className={classes.chips}>
        <Chip
          className={classes.enPrimeurChip}
          component={RRLink}
          to="/search?term=tags:(en primeur)"
          label={t('discover:suggestedSearches.suggestions.enPrimeur')}
        />

        <Chip
          className={classes.chip}
          component={RRLink}
          to={`/search?term=${generateAppendedQueryString(
            'WineName',
            SearchChipsGroupData.SuggestedSearchGroups.leftBankFirstGrowths,
          )}`}
          label={t('discover:suggestedSearches.suggestions.leftBankFirstGrowths')}
        />
        <Chip
          className={classes.chip}
          component={RRLink}
          to={`/search?term=${generateAppendedQueryString(
            'WineName',
            SearchChipsGroupData.SuggestedSearchGroups.rightBankFirstGrowths,
          )}`}
          label={t('discover:suggestedSearches.suggestions.rightBankFirstGrowths')}
        />
        <Chip
          className={classes.chip}
          component={RRLink}
          to={`/search?term=${generateAppendedQueryString(
            'WineName',
            SearchChipsGroupData.SuggestedSearchGroups.qualityPointsRatio,
          )}`}
          label={t('discover:suggestedSearches.suggestions.qualityPointsRatio')}
        />
        <Chip
          className={classes.chip}
          component={RRLink}
          to={`/search?term=${generateAppendedQueryString(
            'WineName',
            SearchChipsGroupData.SuggestedSearchGroups.secondWine,
          )}`}
          label={t('discover:suggestedSearches.suggestions.secondWine')}
        />
        <Chip
          className={classes.chip}
          component={RRLink}
          to={`/search?term=${generateAppendedQueryString(
            'WineName',
            SearchChipsGroupData.SuggestedSearchGroups.superSecondLeftBank,
          )}`}
          label={t('discover:suggestedSearches.suggestions.superSecondLeftBank')}
        />
        <Chip
          className={classes.chip}
          component={RRLink}
          to={`/search?term=${generateAppendedQueryString(
            'WineName',
            SearchChipsGroupData.SuggestedSearchGroups.superSecondRightBank,
          )}`}
          label={t('discover:suggestedSearches.suggestions.superSecondRightBank')}
        />
        <Chip
          className={classes.chip}
          component={RRLink}
          to={`/search?term=${generateAppendedQueryString(
            'WineName',
            SearchChipsGroupData.SuggestedSearchGroups.superTuscans,
          )}`}
          label={t('discover:suggestedSearches.suggestions.superTuscans')}
        />
        <Chip
          className={classes.chip}
          component={RRLink}
          to={`/search?term=${generateAppendedQueryString(
            'Producer',
            SearchChipsGroupData.SuggestedSearchGroups.investmentGradeTuscans,
          )}`}
          label={t('discover:suggestedSearches.suggestions.investmentGradeTuscans')}
        />
        <Chip
          className={classes.chip}
          component={RRLink}
          to={`/search?term=${generateAppendedQueryString(
            'Producer',
            SearchChipsGroupData.SuggestedSearchGroups.investmentGradeBarolo,
          )}`}
          label={t('discover:suggestedSearches.suggestions.investmentGradeBarolo')}
        />
      </div>
    </div>
  );
}
