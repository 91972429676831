import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import React from 'react';
import { Namespace, Resources, TFunction } from 'react-i18next';
import { MyOrder, MyOrdersFacets } from '../../graphql/selectors/selectMyOrders';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { uuid } from '../../utils/uuid';
import { OrderDirection } from '../OrderBookModal/types';
import OrderStatusIndicator from '../OrderStatusIndicator';
import { SortOption } from '../Table/SortMenu';
import { Actions, BasicCell, CustomCell, Row, SortDirection, UnitSizeCell, WineNameCell } from '../Table/types';
import { AssetStatus } from '../../types/assetStatus';
import ExpiryDate from './ExpiryDate';
import { formatDateTime } from './formatDateTime';
import { OmsGetMyOrdersQuery } from '../../__generated__/graphql';

export function selectResultsCount(
  gqlResponse: OmsGetMyOrdersQuery | undefined,
  orderType: OrderDirection,
  orderStatus: string,
): number {
  if (isUndefined(gqlResponse)) {
    return 0;
  }

  return gqlResponse.omsGetMyOrders
    .filter((order) => order.direction === orderType)
    .filter((order) => (orderStatus === 'all' ? true : order.status === orderStatus)).length;
}

interface SelectTableDataProps {
  assets: MyOrder[];
  t: TFunction<Namespace<keyof Resources>>;
  handleEdit: (rowId: string) => void;
  handleCancel: (rowId: string) => void;
}

export interface MyBidsTableData extends Row {
  wineName: WineNameCell;
  vintage: BasicCell;
  date: BasicCell;
  myBid: BasicCell;
  highestBid: BasicCell;
  unitSize: UnitSizeCell;
  expiryDate: CustomCell;
  orderStatus: CustomCell;
  outstandingQuantity: BasicCell;
  actions: Actions;
}

export function selectMyBidsTableData({
  assets,
  t,
  handleEdit,
  handleCancel,
}: SelectTableDataProps): MyBidsTableData[] {
  return assets.map((item) => {
    return {
      wineName: {
        appellation: item.appellation,
        region: item.region,
        wineName: item.wineName,
        link: `/product/${item.assetId}`,
      },
      vintage: {
        mainContent: item.vintage,
      },
      unitSize: {
        unitCount: item.unitCount,
        unitSize: item.unitSize,
      },
      date: {
        mainContent: formatDateTime(item.placedDate),
      },
      expiryDate: {
        content: <ExpiryDate expiryDate={item.expiryDate} />,
      },
      myBid: {
        mainContent: formatterWholeNumber.format(item.price),
      },
      orderStatus: {
        content: <OrderStatusIndicator status={item.status} />,
      },
      highestBid: {
        mainContent: !isNull(item.highestBid) ? formatterWholeNumber.format(item.highestBid) : '-',
        'aria-label': 'highestBid',
      },
      outstandingQuantity: {
        mainContent: `${item.outstandingQuantity}/${item.quantity}`,
      },
      actions: {
        options: {
          disabled:
            item.status === AssetStatus.Expired ||
            item.status === AssetStatus.Cancelled ||
            item.status === AssetStatus.Fulfilled,
          actions: [
            { callback: handleEdit, text: t('product:detailsModal.editBidTitle') },
            { callback: handleCancel, text: t('trade:cancelBid') },
          ],
        },
      },
      rowId: item.orderId,
    };
  });
}

export interface MyOffersTableData extends Row {
  wineName: WineNameCell;
  vintage: BasicCell;
  date: BasicCell;
  myOffer: BasicCell;
  lowestOffer: BasicCell;
  unitSize: UnitSizeCell;
  expiryDate: CustomCell;
  outstandingQuantity: BasicCell;
  orderStatus: CustomCell;
}

export function selectMyOffersTableData({
  assets,
  handleCancel,
  handleEdit,
  t,
}: SelectTableDataProps): MyOffersTableData[] {
  return assets.map((item) => {
    return {
      wineName: {
        appellation: item.appellation,
        region: item.region,
        wineName: item.wineName,
        link: `/product/${item.assetId}`,
      },
      vintage: {
        mainContent: item.vintage,
      },
      unitSize: {
        unitCount: item.unitCount,
        unitSize: item.unitSize,
      },
      date: {
        mainContent: formatDateTime(item.placedDate),
      },
      expiryDate: {
        content: <ExpiryDate expiryDate={item.expiryDate} />,
      },
      myOffer: {
        mainContent: formatterWholeNumber.format(item.price),
      },
      orderStatus: {
        content: <OrderStatusIndicator status={item.status} />,
      },
      lowestOffer: {
        mainContent: !isNull(item.lowestOffer) ? formatterWholeNumber.format(item.lowestOffer) : '-',
        'aria-label': 'lowestOffer',
      },
      outstandingQuantity: {
        mainContent: `${item.outstandingQuantity}/${item.quantity}`,
      },
      actions: {
        options: {
          disabled:
            item.status === AssetStatus.Expired ||
            item.status === AssetStatus.Cancelled ||
            item.status === AssetStatus.Fulfilled,
          actions: [
            { callback: handleEdit, text: t('product:detailsModal.editOfferTitle') },
            { callback: handleCancel, text: t('trade:cancelOffer') },
          ],
        },
      },
      rowId: item.orderId,
    };
  });
}

function getBidSortOptions(
  sortDirection: SortDirection,
  activeSortKey: keyof MyOrdersFacets,
  t: TFunction<Namespace<keyof Resources>>,
): SortOption<keyof MyOrdersFacets>[] {
  const sortOptions: SortOption<keyof MyOrdersFacets>[] = [
    {
      id: uuid(),
      direction: 'desc',
      key: 'wineName',
      active: activeSortKey === 'wineName' && sortDirection === 'desc',
      primaryLabel: t('common:wineName'),
      secondaryLabel: t('search:sort.zToA'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'wineName',
      active: activeSortKey === 'wineName' && sortDirection === 'asc',
      primaryLabel: t('common:wineName'),
      secondaryLabel: t('search:sort.aToZ'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'region',
      active: activeSortKey === 'region' && sortDirection === 'desc',
      primaryLabel: t('common:region'),
      secondaryLabel: t('search:sort.zToA'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'region',
      active: activeSortKey === 'region' && sortDirection === 'asc',
      primaryLabel: t('common:region'),
      secondaryLabel: t('search:sort.aToZ'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'price',
      active: activeSortKey === 'price' && sortDirection === 'desc',
      primaryLabel: t('trade:myTable.myBid'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'price',
      active: activeSortKey === 'price' && sortDirection === 'asc',
      primaryLabel: t('trade:myTable.myBid'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'vintage',
      active: activeSortKey === 'vintage' && sortDirection === 'desc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.newestToOldest'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'vintage',
      active: activeSortKey === 'vintage' && sortDirection === 'asc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.oldestToNewest'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'unitCount',
      active: activeSortKey === 'unitCount' && sortDirection === 'desc',
      primaryLabel: t('trade:units'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'unitCount',
      active: activeSortKey === 'unitCount' && sortDirection === 'asc',
      primaryLabel: t('trade:units'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'highestBid',
      active: activeSortKey === 'highestBid' && sortDirection === 'desc',
      primaryLabel: t('product:trading.highestBid'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'highestBid',
      active: activeSortKey === 'highestBid' && sortDirection === 'asc',
      primaryLabel: t('product:trading.highestBid'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
  ];

  return sortOptions;
}

function getOfferSortOptions(
  sortDirection: SortDirection,
  activeSortKey: keyof MyOrdersFacets,
  t: TFunction<Namespace<keyof Resources>>,
): SortOption<keyof MyOrdersFacets>[] {
  const sortOptions: SortOption<keyof MyOrdersFacets>[] = [
    {
      id: uuid(),
      direction: 'desc',
      key: 'wineName',
      active: activeSortKey === 'wineName' && sortDirection === 'desc',
      primaryLabel: t('common:wineName'),
      secondaryLabel: t('search:sort.zToA'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'wineName',
      active: activeSortKey === 'wineName' && sortDirection === 'asc',
      primaryLabel: t('common:wineName'),
      secondaryLabel: t('search:sort.aToZ'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'region',
      active: activeSortKey === 'region' && sortDirection === 'desc',
      primaryLabel: t('common:region'),
      secondaryLabel: t('search:sort.zToA'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'region',
      active: activeSortKey === 'region' && sortDirection === 'asc',
      primaryLabel: t('common:region'),
      secondaryLabel: t('search:sort.aToZ'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'price',
      active: activeSortKey === 'price' && sortDirection === 'desc',
      primaryLabel: t('trade:myTable.myOffer'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'price',
      active: activeSortKey === 'price' && sortDirection === 'asc',
      primaryLabel: t('trade:myTable.myOffer'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'vintage',
      active: activeSortKey === 'vintage' && sortDirection === 'desc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.newestToOldest'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'vintage',
      active: activeSortKey === 'vintage' && sortDirection === 'asc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.oldestToNewest'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'unitCount',
      active: activeSortKey === 'unitCount' && sortDirection === 'desc',
      primaryLabel: t('trade:units'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'unitCount',
      active: activeSortKey === 'unitCount' && sortDirection === 'asc',
      primaryLabel: t('trade:units'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'lowestOffer',
      active: activeSortKey === 'lowestOffer' && sortDirection === 'desc',
      primaryLabel: t('product:trading.lowestOffer'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'lowestOffer',
      active: activeSortKey === 'lowestOffer' && sortDirection === 'asc',
      primaryLabel: t('product:trading.lowestOffer'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
  ];

  return sortOptions;
}

export function getSortOptions(
  sortDirection: SortDirection,
  activeSortKey: keyof MyOrdersFacets,
  t: TFunction<Namespace<keyof Resources>>,
  orderType: OrderDirection,
): SortOption<keyof MyOrdersFacets>[] {
  return orderType === OrderDirection.Bids
    ? getBidSortOptions(sortDirection, activeSortKey, t)
    : getOfferSortOptions(sortDirection, activeSortKey, t);
}
