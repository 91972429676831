import { useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React, { useState } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import ModalContentSwitcher, { ModalStep } from '../Dialog/ContentSwitcher';
import FailureContent from './FailureContent';
import FormContent from './FormContent';
import SuccessContent from './SuccessContent';
import { FormState } from './types';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

function selectModalStyles(step: ModalStep, lowerThanMd: boolean) {
  if (step === ModalStep.Initial) {
    return { minHeight: 350, minWidth: lowerThanMd ? 550 : 768, maxWidth: lowerThanMd ? 550 : 768, width: '100%' };
  }
  return { minHeight: 370, minWidth: lowerThanMd ? 380 : 600, maxWidth: lowerThanMd ? 550 : 768, width: '100%' };
}

export default function AddPaymentOptionModal({ open, onClose }: ModalProps): JSX.Element {
  const theme = useTheme();
  const lowerThanMd = useMediaQuery(theme.breakpoints.down('lg'));
  const defaultFormState = {
    cardHolderName: '',
    cardNumber: '',
    expiry: '',
    cvv: '',
    makeAsDefault: false,
  };
  const [formState, setFormState] = useState<FormState>(defaultFormState);
  const [currentModalStep, setCurrentModalStep] = useState(ModalStep.Initial);

  function onFormSubmitSuccess(state: FormState) {
    setFormState(state);
    // TODO: Call mutation here and navigate to Success or Failure depending on the response
    setCurrentModalStep(ModalStep.Success);
  }

  function onCloseWithModalStateReset() {
    onClose();
    setFormState(defaultFormState);
    // So we don't see flicker of screen changing, before visibility animation finishes
    setTimeout(() => {
      setCurrentModalStep(ModalStep.Initial);
    }, 500);
  }
  return (
    <Dialog onClose={onCloseWithModalStateReset} open={open} disableEscapeKeyDown maxWidth="md">
      <ModalContentSwitcher
        modalStep={currentModalStep}
        selectStyles={() => selectModalStyles(currentModalStep, lowerThanMd)}
        InitialComponent={() => (
          <FormContent formState={formState} onClose={onCloseWithModalStateReset} onSuccess={onFormSubmitSuccess} />
        )}
        SuccessComponent={() => <SuccessContent onClose={onCloseWithModalStateReset} />}
        FailureComponent={() => <FailureContent onClose={onCloseWithModalStateReset} />}
      />
    </Dialog>
  );
}
