import { useMutation, useReactiveVar } from '@apollo/client';
import useFilters from '@cultwines/zellar-client-sdk/hooks/useFilters';
import { Backdrop, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppLayoutContext } from '../../components/AppLayout/Context';
import SearchFiltersComponent from '../../components/SearchFilters';
import SearchResults from '../../components/SearchResults';
import { WineVintage } from '../../components/SearchResults/types';
import { SortDirection } from '../../components/Table/types';
import { appIsWorkingVar } from '../../graphql/cache';
import { useQueryParameters } from '../../hooks';
import { isNull } from '../../utils/isNull';
import { logError } from '../../utils/logger';
import { SearchWinesFacets, selectError, selectWines, selectWinesCount } from './selectors';
import { WineVintageResult } from '../../__generated__/graphql';
import { SEARCH_WINE_VINTAGES_RESULTS } from '../../graphql/mutations/searchWineVintagesResults';
import { buildActiveFilterString } from '../../components/SearchFilters/helper';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gap: theme.spacing(4),
    },
  },

  filters: {
    background: theme.palette.grey[50],
  },
  searchResults: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      minWidth: '75%',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const PAGE_SIZE_VARIANTS = [20, 60, 90];

export default function Search(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { desktopOnly } = useFlags();
  const { dispatch: appLayoutDispatch } = useAppLayoutContext();
  const query = useQueryParameters();
  const history = useHistory();
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE_VARIANTS[1]);
  const [wines, setWines] = React.useState<WineVintage[]>([]);
  const [winesCount, setWinesCount] = React.useState<number>(0);
  const from = React.useMemo(() => page * pageSize, [page, pageSize]);
  const [sortOrder, setSortOrder] = React.useState<SortDirection | null>(null);
  const [sortFacet, setSortFacet] = React.useState<keyof SearchWinesFacets | null>(null);
  const searchQuery = query.get('term');
  const appIsWorking = useReactiveVar(appIsWorkingVar);
  const { state: activeFiltersState, dispatch } = useFilters<SearchWinesFacets>();
  const [searchWineVintages, { loading: performingSearch, error: failedToPerformSearch }] =
    useMutation(SEARCH_WINE_VINTAGES_RESULTS);
  if (searchQuery === null || searchQuery.length === 0) {
    history.push('/discover');
  }

  // Simply initialise the useFilters hook with the search term when it changes.
  // We will rely on the replacement of filters every time the querystring changes and a new
  // search request is performed.
  React.useEffect(() => {
    if (!isNull(searchQuery)) {
      dispatch({
        type: 'initialise',
        payload: { searchTerm: searchQuery!, preQueryString: searchQuery || undefined },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  React.useEffect(() => {
    if (failedToPerformSearch) {
      logError({
        originalError: failedToPerformSearch,
        error: new Error('Failed to perform search'),
        filename: 'Search',
        additionalInfo: {
          searchTerm: searchQuery ?? '',
        },
        tags: {
          userFlow: 'search',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedToPerformSearch]);

  /**
   * This useEffect is fired only when the query string changes, and is intentionally kept
   * separate from the useEffect that fires when sortOrder, sortFilter, pageSize, pageNumber change, because
   * we only want to merge facets when the query string has changed. Changing page size or sort order etc. will
   * not result in any change to the facets we get back, and thus we only ever should need to merge the facets
   * when the query string is updated.
   */
  React.useEffect(() => {
    async function performSearch() {
      const result = await searchWineVintages({
        variables: {
          queryString: activeFiltersState.queryString,
          from,
          pageSize,
          sortOrder: sortOrder === null ? undefined : sortOrder,
          preQueryString: isNull(activeFiltersState.preQueryString)
            ? ''
            : buildActiveFilterString(activeFiltersState.activeFilters),
          sortFilter: sortFacet === null ? undefined : sortFacet,
          filterNames: activeFiltersState.postFilters.map((postFilter) => postFilter.toString()),
        },
      });
      setWines(selectWines(result.data?.searchWineVintages?.results as WineVintageResult[], t));
      setWinesCount(selectWinesCount(result.data));
      setPage(0);
    }

    if (activeFiltersState.queryString.length === 0) {
      return;
    }

    if (activeFiltersState.queryString.length > 0) {
      performSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFiltersState.queryString, activeFiltersState.preQueryString]);

  React.useEffect(() => {
    async function performSearch() {
      const result = await searchWineVintages({
        variables: {
          queryString: activeFiltersState.queryString,
          from,
          pageSize,
          sortOrder: sortOrder === null ? undefined : sortOrder,
          preQueryString: isNull(activeFiltersState.preQueryString) ? '' : activeFiltersState.preQueryString,
          sortFilter: sortFacet === null ? undefined : sortFacet,
          filterNames: activeFiltersState.postFilters.map((postFilter) => postFilter.toString()),
        },
      });
      setWines(selectWines(result.data?.searchWineVintages?.results as WineVintageResult[], t));
    }

    if (!performingSearch && activeFiltersState.queryString.length > 0) {
      performSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder, sortFacet, pageSize, from]);

  const location = useLocation();

  React.useEffect(() => {
    if (location.state?.fromTopPerformance) {
      setSortFacet('PercentageDifference');
      setSortOrder('desc');
    }
    if (query.get('term')?.includes('tags')) {
      setSortFacet('WineName');
      setSortOrder('asc');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.fromTopPerformance]);

  React.useEffect(() => {
    appIsWorkingVar(performingSearch && !filtersOpen);
  }, [performingSearch, filtersOpen]);

  function handleFilterDrawerClosed(): void {
    setFiltersOpen(false);
  }

  function handleFilterDrawerOpened(): void {
    setFiltersOpen(true);
  }

  function handleChangePage(_: unknown, newPage: number): void {
    if (!desktopOnly) {
      appLayoutDispatch({ type: 'appLayout/scrollTo', payload: { x: 0, y: 0 } });
    }
    setPage(newPage);
  }

  function handleChangeResultPerPage(event: React.ChangeEvent<HTMLInputElement>): void {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleSortUpdated(key: keyof SearchWinesFacets, direction?: SortDirection): void {
    if (!direction) {
      if (key !== sortFacet) {
        // a different facet has been selected
        setSortFacet(key);
        setSortOrder('asc');
      } else {
        // it's just the sort direction that has changed
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      }
    } else {
      setSortFacet(key);
      setSortOrder(direction);
    }
  }
  return (
    <div className={classes.container}>
      <Backdrop className={classes.backdrop} open={appIsWorking}>
        <CircularProgress size={42} />
      </Backdrop>
      {!failedToPerformSearch && (
        <Drawer anchor="left" open={filtersOpen} onClose={handleFilterDrawerClosed}>
          <SearchFiltersComponent
            className={classes.filters}
            activeFilters={activeFiltersState.activeFilters}
            facets={activeFiltersState.facets}
            dispatch={dispatch}
            onClose={handleFilterDrawerClosed}
            filterNames={activeFiltersState.postFilters.map((postFilter) => postFilter.toString())}
            preQueryString={isNull(activeFiltersState.preQueryString) ? '' : activeFiltersState.preQueryString}
            queryString={activeFiltersState.queryString}
          />
        </Drawer>
      )}
      <SearchResults
        className={classes.searchResults}
        results={wines}
        resultsCount={winesCount}
        loading={performingSearch}
        error={selectError(failedToPerformSearch)}
        page={page}
        pageSizeVariants={PAGE_SIZE_VARIANTS}
        rowsPerPage={pageSize}
        activeFiltersCount={activeFiltersState.activeFilters.length}
        onChangePage={handleChangePage}
        onRowsPerPageChanged={handleChangeResultPerPage}
        activeSortKey={sortFacet}
        onSortChanged={handleSortUpdated}
        sortDirection={sortOrder}
        onFiltersOpened={handleFilterDrawerOpened}
      />
    </div>
  );
}
