import React from 'react';

type ScrollToAction = { type: 'appLayout/scrollTo'; payload: { x: number; y: number } };
type Action = ScrollToAction;
type Dispatch = React.Dispatch<Action>;
type State = { scrollX: number; _ref: React.RefObject<HTMLDivElement> };
type AppLayoutContextProps = {
  children: React.ReactNode;
};

const AppLayoutContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'appLayout/scrollTo': {
      state._ref.current?.scroll(action.payload.x, action.payload.y);
      return { ...state, scrollX: action.payload.x };
    }

    default:
      return state;
  }
}
type Reducer = typeof reducer;

function AppLayoutContextProvider({ children }: AppLayoutContextProps): JSX.Element {
  const ref = React.createRef<HTMLDivElement>();
  const initialState: State = {
    _ref: ref,
    scrollX: 0,
  };
  const [state, dispatch] = React.useReducer<Reducer, State>(reducer, initialState, () => initialState);

  return <AppLayoutContext.Provider value={{ dispatch, state }}>{children}</AppLayoutContext.Provider>;
}

function useAppLayoutContext(): { state: State; dispatch: Dispatch } {
  const context = React.useContext(AppLayoutContext);
  if (context === undefined) {
    throw new Error('useAppLayoutContext must be used within a ContentBottomPaddingProvider');
  }

  return context;
}

export { AppLayoutContextProvider, useAppLayoutContext };
