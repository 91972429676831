/* eslint-disable no-nested-ternary */
import makeStyles from '@mui/styles/makeStyles';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelectedRowsContext } from '../../context/selectedRowsContext';
import EmptyPlaceholder from '../EmptyPlaceholder';
import ErrorPlaceholder from '../ErrorPlaceholder';
import Header from './Cells/Header';
import RowComponent from './Row';
import { Actions, ExpandedContentProps, Headers, Row } from './types';
import { hasActions } from './utils';

const useStyles = makeStyles(() => ({
  placeholderContainer: {
    textAlign: 'center',
  },
}));

const ROW_HEIGHT = 70;

interface TableProps<T extends Row, K> {
  loading: boolean;
  headers: Headers<Omit<T, 'rowId' | 'actions' | 'asset'>, K>;
  data: T[];
  actions?: Actions;
  emptyTableText: string;
  error: string | null;
  EmptyTableAction?: React.ReactNode;
  ErrorAction?: React.ReactNode;
  SubContent?: (args: ExpandedContentProps) => JSX.Element;

  /**
   * If you want to avoid having checkboxes show up for the expanded table rows etc.
   */
  disableMultiSelect?: boolean;
}

/**
 * ⚠️ This component relies on the `SelectedRowsProvider` being placed higher up in the component tree.
 *
 * @deprecated Use src/components/TableElements instead.
 */
export default function Table<T extends Row, K>({
  loading,
  headers,
  data,
  emptyTableText,
  actions = {},
  EmptyTableAction,
  ErrorAction,
  SubContent,
  error,
  disableMultiSelect = false,
}: TableProps<T, K>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { state } = useSelectedRowsContext();

  /**
   * Column count might be different than headers.length, because
   * we don't want to show headers for some columns, namely the
   * expander column (if there is subContent), and the action column
   * (if any action buttons are required).
   */
  const columnCount = useMemo(() => {
    let colCount = Object.keys(headers).length;

    // We will need an extra column for the actions
    if (hasActions(data, actions)) {
      colCount += 1;
    }

    // We will need to prepend a column for the expander button
    if (SubContent) {
      colCount += 1;
    }

    return colCount;
  }, [headers, data, actions, SubContent]);

  return (
    <div>
      <TableContainer>
        <MuiTable aria-label="table">
          <TableHead>
            <TableRow>
              <>
                {state.selectModeActive && !disableMultiSelect && <Header />}
                {SubContent && !state.selectModeActive && <Header />}
                {Object.keys(headers).map((key, index) => {
                  const header = headers[key as keyof typeof headers];
                  return (
                    <Header
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      title={header.title}
                      onClick={header.onClick}
                      id={header.id}
                      sortDirection={header.direction}
                      active={header.active}
                      extraElement={header.extraElement}
                    />
                  );
                })}
                {hasActions(data, actions) && <Header title={t('common:actions')} />}
              </>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <>
                {new Array(6).fill(0).map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={i}>
                    <TableCell colSpan={columnCount}>
                      <Skeleton
                        width="100%"
                        variant="rectangular"
                        height={ROW_HEIGHT}
                        aria-label="display-row-skeleton"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : !data.length && !error ? (
              <TableCell className={classes.placeholderContainer} colSpan={12}>
                <EmptyPlaceholder label={emptyTableText} ActionButton={EmptyTableAction} />
              </TableCell>
            ) : error ? (
              <TableCell className={classes.placeholderContainer} colSpan={12}>
                <ErrorPlaceholder error={error} action={ErrorAction} />
              </TableCell>
            ) : (
              data.map((datum) => {
                return (
                  <RowComponent
                    key={datum.rowId}
                    datum={datum}
                    headers={headers}
                    actions={datum.actions ?? actions}
                    columnCount={columnCount}
                    SubContent={SubContent}
                    disableSelect={disableMultiSelect}
                  />
                );
              })
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </div>
  );
}
