import { ApolloError, useQuery } from '@apollo/client';
import { CALCULATED_MARKET_DATA } from '../graphql/queries/calculatedMarketData';
import { CalculatedMarketDataQuery, CalculatedMarketDataQueryVariables } from '../__generated__/graphql';

interface HookResponse {
  data: CalculatedMarketDataQuery | undefined;
  error: ApolloError | undefined;
  loading: boolean;
}

export const useCalculatedMarketData = ({ assetId }: CalculatedMarketDataQueryVariables): HookResponse => {
  const { data, loading, error } = useQuery(CALCULATED_MARKET_DATA, {
    variables: {
      assetId,
    },
  });

  return { data, loading, error };
};
