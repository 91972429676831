import React from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '../../Typography';
import { BasicCell as BasicCellProps } from '../types';

export default function BasicCell({ mainContent, subContent, ...rest }: BasicCellProps): JSX.Element {
  return (
    <TableCell aria-label={rest['aria-label'] === undefined ? '' : rest['aria-label']}>
      <>
        <Typography variant="subtitle2">{mainContent}</Typography>
        {subContent && (
          <Typography zellarColour="textLabel" variant="body2">
            {subContent}
          </Typography>
        )}
      </>
    </TableCell>
  );
}
