import React from 'react';
import TableCell from '@mui/material/TableCell';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { SortDirection } from '../types';

const useStyles = makeStyles<Theme, { clickable: boolean }>((theme) => ({
  uppercase: {
    textTransform: 'uppercase',
  },

  root: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    background: theme.palette.grey[50],
  },

  active: {
    fontWeight: 700,
  },

  icon: {
    color: theme.palette.textLabel,
    cursor: 'pointer',
  },

  inactiveIcon: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },

  activeIcon: {
    opacity: 1,
  },

  inner: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

interface HeaderProps<K> {
  title?: string;
  id?: K;
  active?: boolean;
  sortDirection?: SortDirection;
  onClick?: (key: K, direction: SortDirection) => void;
  extraElement?: JSX.Element;
}

/**
 * @deprecated use material-ui and src/components/TableElements
 */
export default function Header<K>({
  onClick,
  id,
  active,
  sortDirection,
  title = '',
  extraElement,
}: HeaderProps<K>): JSX.Element {
  const clickable = React.useMemo(() => !isUndefined(onClick), [onClick]);
  const classes = useStyles({ clickable });

  const handleClick = React.useCallback(() => {
    if (isUndefined(onClick) || isUndefined(id)) {
      return;
    }

    // eslint-disable-next-line no-nested-ternary
    onClick(id, isUndefined(sortDirection) ? 'desc' : sortDirection === 'asc' ? 'desc' : 'asc');
  }, [id, onClick, sortDirection]);

  const Arrow = React.useMemo(() => {
    if (!active) {
      return <ArrowDropDown onClick={handleClick} className={`${classes.icon} ${classes.inactiveIcon}`} />;
    }

    return sortDirection === 'asc' ? (
      <ArrowDropUp onClick={handleClick} className={`${classes.icon} ${classes.activeIcon}`} />
    ) : (
      <ArrowDropDown onClick={handleClick} className={`${classes.icon} ${classes.activeIcon}`} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, sortDirection, handleClick]);

  return (
    <TableCell classes={{ root: classes.root }} variant="head" className={classes.uppercase}>
      <div className={classes.inner}>
        <span className={active ? classes.active : ''}>
          {title}
          {extraElement}
        </span>
        {clickable && Arrow}
      </div>
    </TableCell>
  );
}
