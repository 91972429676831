import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../hooks/useMediaQuery';
import TabPanel from '../TabPanel';
import DetailsSection from './Details';
import ScoreSection from './Score';

const useStyles = makeStyles(() => ({
  tab: {
    minWidth: 100,
  },
}));

interface Props {
  assetId: number;
}

export default function ProductTabs({ assetId }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const greaterThanXl = useMediaQuery(theme.breakpoints.up('xl'));

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        indicatorColor="primary"
        onChange={handleChange}
        variant={greaterThanXl ? 'standard' : 'scrollable'}
        centered={greaterThanXl}
      >
        <Tab className={classes.tab} label={t('product:details')} />
        <Tab className={classes.tab} label={t('product:scores')} />
        {/* The following is ready for MVP launch */}
        {/* <Tab className={classes.tab} label={t('product:news')} disabled /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <DetailsSection />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ScoreSection assetId={assetId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        TBD news component
      </TabPanel>
    </div>
  );
}
