import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import { DialogActions, Divider, Skeleton, Stack, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingButton } from '@mui/lab';
import Typography from '../Typography';
import DialogTitle from '../Dialog/DialogTitle';
import { formatter } from '../../utils/currencyFormatter';
import { useWalletBalance } from '../../hooks';
import { DialogContent } from '../Dialog/DialogContent';
import PayByCard from './PayByCard';
import ManualBankTransfer from './ManualBankTransfer';
import { getInsertAmountValidationSchema } from './helpers';
import InsertAmountField from '../WithdrawFundsModal/components/InsertAmountField';
import BillingAddress from './BillingAddress';
import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit-card.svg';
import { useAddress } from '../../hooks/useAddress';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    amountLabel: {
      marginBottom: theme.spacing(3),
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    balanceContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      gap: 2,
      textAlign: 'end',
    },
    paymentOptions: {
      display: 'flex',
      paddingBottom: 0,
    },
    payNowButton: {
      marginTop: '12px',
    },
    cardButton: {
      width: '100%',
      background: 'white',
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 4,
      padding: theme.spacing(3),
    },
    cardIcon: {
      stroke: theme.palette.textPrimary,
    },
    buttonContainer: {
      paddingTop: 0,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      background: theme.palette.grey[50],
    },
  };
});

interface PaymentMethodProps {
  onClose: () => void;
  onBankDetail: () => void;
  openAddressModal: () => void;
}

export default function PaymentMethod({ onClose, onBankDetail, openAddressModal }: PaymentMethodProps): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const { walletBalance, loading } = useWalletBalance();
  const { payInViaManualBankTransfer, billingAddressTopUpModal13811 } = useFlags();
  const { address, loading: loadingAddress, error: addressError } = useAddress({ addressType: 'Billing' });
  const insertAmountValidationSchema = getInsertAmountValidationSchema(t);
  const [amount, setAmount] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const onFieldChange = (value: string) => {
    setAmount(value.replace(/,/g, ''));
  };

  const CurrentBalance = (): JSX.Element => {
    return (
      <div className={classes.balanceContainer}>
        <Typography variant="overline" zellarColour="textLabel" className={classes.uppercase}>
          {t('wallet:totalFunds')}
        </Typography>
        {loading ? (
          <Typography variant="h4">
            <Skeleton width="40px" />
          </Typography>
        ) : (
          <Typography variant="h4">
            {isNull(walletBalance) ? t('common:currentlyUnavailable') : formatter.format(walletBalance)}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {t('wallet:paymentMethodModal.title')}
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.container}>
          <InsertAmountField
            onError={onFieldChange}
            value={amount}
            placeholder={t('wallet:topUpModal.amount.placeholder')}
            onChange={onFieldChange}
            validationSchema={insertAmountValidationSchema}
          />
          <CurrentBalance />
        </div>
      </DialogContent>
      <DialogContent className={classes.paymentOptions}>
        <div className={classes.cardButton}>
          <CreditCardIcon className={classes.cardIcon} />
          <Typography variant="subtitle1">{t('wallet:payByCard.title')}</Typography>
        </div>

        <Stack bgcolor={theme.palette.grey[50]} divider={<Divider />} mx={1}>
          <PayByCard
            amount={Number(amount)}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            setIsFormValid={setIsFormValid}
          />
          {payInViaManualBankTransfer && (
            <ManualBankTransfer amount={Number(amount)} onBankTransferSelected={onBankDetail} />
          )}
          {billingAddressTopUpModal13811 && <BillingAddress address={address} openAddressModal={openAddressModal} />}
        </Stack>
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <LoadingButton
          className={classes.payNowButton}
          color="primary"
          variant="contained"
          size="large"
          loading={isSubmitting}
          onClick={() => setIsSubmitting(true)}
          disabled={
            Number(amount) <= 0 || loadingAddress || !!addressError || isNull(address) || isSubmitting || !isFormValid
          }
        >
          {t('wallet:payByCard.cta')}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
