import React from 'react';
import { useTranslation } from 'react-i18next';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '../Typography';
import { ReactComponent as RegionIcon } from '../../assets/icons/wine-details-region.svg';
import { ReactComponent as AppellationIcon } from '../../assets/icons/wine-details-appellation.svg';
import { ReactComponent as ProducerIcon } from '../../assets/icons/wine-details-producer.svg';

const useStyles = makeStyles((theme) => ({
  inner: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  iconContainer: {
    padding: 6,
    background: theme.palette.grey[50],
    borderRadius: 4,
  },
  icon: {
    width: '2rem',
    height: '2rem',
    stroke: theme.palette.textPrimary,
  },
  text: { whiteSpace: 'break-spaces' },
}));

interface SuggestionProps {
  title: string;
  suggestion: string;
  count?: number;
  onClick: (region: string) => void;
}

export const componentIcon = (title: string): JSX.Element | null => {
  switch (title) {
    case 'Region':
      return <RegionIcon />;
    case 'Appellation':
      return <AppellationIcon />;
    case 'Producer':
      return <ProducerIcon />;
    default:
      return null;
  }
};

export default function Suggestion({ title, suggestion, count, onClick }: SuggestionProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <MenuItem onClick={() => onClick(suggestion)} disableRipple>
      <div className={classes.inner}>
        <div className={classes.iconContainer}>
          <div className={classes.icon}>{componentIcon(title)}</div>
        </div>
        <div>
          <Typography className={classes.text} customVariant="subtitle1">
            {suggestion}
          </Typography>
          {!isUndefined(count) && (
            <Typography customVariant="body2" zellarColour="textInformation">
              {count} {t('search:searchResults')}
            </Typography>
          )}
        </div>
      </div>
    </MenuItem>
  );
}
