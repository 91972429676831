import { graphql } from '../../__generated__';

export const SEARCH_ACTIVE_MARKETS = graphql(`
  query SearchActiveMarkets($from: Int!, $pageSize: Int!, $sortFilter: String, $sortOrder: String, $wineName: String) {
    searchActiveMarkets(
      from: $from
      pageSize: $pageSize
      sortFilter: $sortFilter
      sortOrder: $sortOrder
      wineName: $wineName
    ) {
      total
      results {
        assetId
        highestBid {
          createdDate
          price
        }
        lowestOffer {
          createdDate
          price
        }
        marketValue
        region
        unitSize
        unitCount
        imageFileName
        vintage
        wineName
        spread
      }
    }
  }
`);
