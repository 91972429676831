import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import Typography from '../../Typography';
import { WineNameCell as WineNameCellProps } from '../types';

export default function WineNameCell({ wineName, region, appellation, link }: WineNameCellProps): JSX.Element {
  return (
    <TableCell variant="body">
      <Link component={RRLink} to={link} color="textPrimary">
        <Typography variant="subtitle1">{wineName}</Typography>
      </Link>
      {(region || appellation) && (
        <Typography variant="body2" zellarColour="textInformation">
          {region && appellation && `${region} • ${appellation}`}
          {region && !appellation && `${region}`}
          {!region && appellation && `${appellation}`}
        </Typography>
      )}
    </TableCell>
  );
}
